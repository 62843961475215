import StepperComponent from "../../components/molecules/stepper";
import LEARNING_CONTENT_DATA from "../en.json";

const {
  Cpp_What_You_Will_Learn,
  Cpp_How_It_Works,
  Eligibility_Criteria,
  How_It_Works,
  What_You_will_learn,
  Cpp_Eligibility_Criteria,
} = LEARNING_CONTENT_DATA;

const cppTabsData = [
  {
    iconContent: Eligibility_Criteria,
    details: Cpp_Eligibility_Criteria,
  },
  {
    iconContent: How_It_Works,
    details: (
      <>
        <StepperComponent steps={Cpp_How_It_Works} />
      </>
    ),
  },
  {
    iconContent: What_You_will_learn,
    details: (
      <>
        <StepperComponent steps={Cpp_What_You_Will_Learn} />
      </>
    ),
  },
];

export default cppTabsData;
