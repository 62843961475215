export const ABOUTUS = {
  _About_Us:
    " DSEdify, a product of Dyashin Technosoft, is your ultimate destination for comprehensive learning solutions. Our innovative Learning Management System (LMS) is designed to empower individuals, educational institutions, and organizations through transformative IT education.",
};

export const CAMPUSPROGRAM = {
  _Campus_Program:
    " Our corporate learning programs are tailored to elevate your workforce's skills, aligning with business objectives. Designed by industry experts, these programs empower teams to meet evolving challenges and drive organizational success.",
};

export const OFFCAMPUS = {
  _off_Campus:
    " DSEdify retail learning programs cater to off-campus graduates, skill upgradation enthusiasts, and working professionals aspiring to transition into the IT industry, providing tailored education to fuel career advancements and industry relevance.",
};
export const CORPORATE = {
  _Corporate_Program:
    " Our campus ready program goes beyond traditional education, offering a holistic approach to prepare students for the challenges and opportunities that lie ahead. it's a comprehensive blend of academic excellence, practical skills development, and personal growth initiatives.",
};
export const INTERSHIPPROGRAM = {
  _Intership_Program:
    "DSEdify's campus internship program goes beyond traditional education, offering a holistic approach to prepare students for the challenges and opportunities that lie ahead. it's a comprehensive blend of academic excellence, practical skills development, and personal growth initiatives.",
};

export const CAMPUSPROJECT = {
  _Campus_Project:
    "DSEdify's Professional Development Program at Dyashin Technosoft, ensuring job placement from day one. Candidates undergo a comprehensive six-month technology learning phase, followed by an immersive six-month stint working on live projects—an innovative approach, saving time by combining  training and professional experience for a seamless transition into the workforce.",
};
export const PROFESSIONALPROGRAM = {
  _Professional_Program:
    " DSEdify's Professional Development Program at Dyashin Technosoft, ensuring job placement from day one. Candidates undergo a comprehensive six-month technology learning phase,followed by an immersive six-month stint working on live projects—an innovative approach, saving time by combining training and professional experience for a seamless transition into the workforce.",
};

export const EXPERIENTIAL = {
  _Experiential_Program:
    "Our tailor made subscriptions help the learners take the best advantage of the courses or programs in edify.",
};
