import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function TabsCardComponent({
  icon = "",
  iconContent = "Product Design",
  selected = false,
  index = 0,
  sxcard = {},
  sxtext = {},
  onCardClick = () => {},
}) {
  return (
    <Grid container className="h-100">
      <Grid
        container
        item
        sm={12}
        xs={12}
        bgcolor={selected ? "rgba(36, 171, 227, 1)" : "white"}
        onClick={() => {
          onCardClick(index);
        }}
        sx={{ ...sxcard }}
        height={45}
        className="cursor-pointer"
        width={{ md: 150, xs: 100, sm: 150 }}
      >
        <Grid item xs={12} className="d-flex justify-content-center">
          {icon && <Box>{icon}</Box>}
        </Grid>
        <Grid item xs={12}>
          <Typography
            className="fw-700 fs-12 text-center py-2 "
            color={selected ? "white" : " black"}
            sx={{ ...sxtext }}
          >
            {iconContent}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TabsCardComponent;
