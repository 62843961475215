import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PdfRedirect = ({ pdfUrl }) => {
  const history = useNavigate();
  useEffect(() => {
    window.open(pdfUrl, "_blank");
    history("/");
  }, [pdfUrl, history]);

  return null;
};

export default PdfRedirect;
