import { Typography } from "@mui/material";
import StepperComponent from "../../components/molecules/stepper";
import LEARNING_CONTENT_DATA from "../en.json";
import cppTabsData from "../ProgramsTabsData/cppTabsData";
import tcpTabsData from "./tcpTabsData";
import sspTabsData from "./sspTabsData";
import fdpTabsData from "./fdpTabsData";
import fmpTabsData from "./fmpTabsData";

const {
  CAMPUS_PROGRAMS_DATA,
  Cip_What_You_Will_Learn,
  Cip_How_It_Works,
  Cip_What_You_Will_Learn_Title,
  Cip_Eligibility_Criteria,
  Eligibility_Criteria,
  How_It_Works,
  What_You_will_learn,
} = LEARNING_CONTENT_DATA;

const cipTabsData = [
  {
    iconContent: Eligibility_Criteria,
    details: Cip_Eligibility_Criteria,
  },

  {
    iconContent: How_It_Works,
    details: (
      <>
        <StepperComponent steps={Cip_How_It_Works} />
      </>
    ),
  },
  {
    iconContent: What_You_will_learn,
    details: (
      <>
        <Typography className="fw-600 mb-3 cip">
          {Cip_What_You_Will_Learn_Title}{" "}
        </Typography>
        <StepperComponent steps={Cip_What_You_Will_Learn} />
      </>
    ),
  },
];

const allTabsData = {
  cipTabsData,
  cppTabsData,
  tcpTabsData,
  sspTabsData,
  fdpTabsData,
  fmpTabsData,
};

const CAMPUS_PROGRAMS_CONTENT = CAMPUS_PROGRAMS_DATA.map((program) => {
  return {
    id: program._id_,
    title: program._title_,
    description: program._description_,
    image: program._image_,
    buttonLabel: program._buttonLabel_,
    tabsData: allTabsData[program._tabsData_],
  };
});

export default CAMPUS_PROGRAMS_CONTENT;
