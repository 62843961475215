import React from "react";
import PopOverComponent from "../PopOver/popOverComponent";

const StepperComponent = ({ steps, activeStep }) => {
  return (
    <div className="stepper-container">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`stepper-item ${index <= activeStep ? "active" : ""} px-3`}
        >
          <div className="stepper-number fs-20 fw-400">{index + 1}</div>
          <div className="stepper-heading w-100p">{step.heading}</div>
          {Array.isArray(step.description) ? (
            <PopOverComponent>
              <ul className="stepper-description list-item popOver-content">
                {step.description.map((point, i) => (
                  <li key={i}>{point}</li>
                ))}
              </ul>
            </PopOverComponent>
          ) : (
            <PopOverComponent>
              <div className="stepper-description popOver-content">
                {step.description}
              </div>
            </PopOverComponent>
          )}
          {index < steps.length - 1 && (
            <div className="stepper-connector"></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default StepperComponent;
