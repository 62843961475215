import LEARNING_CONTENT_DATA from "../en.json";

const {
  ilp_What_You_Will_Learn,
  ilp_How_It_Works,
  Eligibility_Criteria,
  How_It_Works,
  What_You_will_learn,
  ilp_Eligibility_Criteria_Description,
} = LEARNING_CONTENT_DATA;

const ilpTabsData = [
  {
    iconContent: Eligibility_Criteria,
    description: ilp_Eligibility_Criteria_Description,
  },
  {
    iconContent: How_It_Works,
    details: ilp_How_It_Works,
  },
  {
    iconContent: What_You_will_learn,
    details: ilp_What_You_Will_Learn,
  },
];
export default ilpTabsData;
