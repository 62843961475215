import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AboutCareerCard from "../../molecules/cards/AboutCareerCard";
import LEARNING_CONTENT_DATA from "../../../constants/en.json";
import ProgramCard from "../../molecules/programCard/ProgramCard";
import OFFCAMPUS_PROGRAMS_CONTENT from "../../../constants/ProgramsTabsData/pdpTabsData";
const { OFFCAMPUS_PROGRAMS } = LEARNING_CONTENT_DATA;

let S3_URL = process.env.REACT_APP_DYASHIN_S3_URL;
let EDIFY_S3_URL = process.env.REACT_APP_DSEDIFY_S3_URL;

let PDP = S3_URL + `/assets/services/PDPLogo.svg`;
let ELIP = S3_URL + `/assets/services/Group%2024841@2x.png`;

function OffCampusPrograms() {
  const { section } = useParams();

  useEffect(() => {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        const yOffset = -60;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [section]);

  let navigate = useNavigate();

  const handleButtonClick = (buttonLabel) => {
    if (buttonLabel === "Enroll") {
      navigate("/enquiry");
    }
  };
  return (
    <>
      <Box>
        <Box className="py-5 darkBlueBackground" id="campus-programs">
          <Grid container rowGap={2} rowSpacing={2} spacing={3}>
            <Grid item xs={0} md={1.5}></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box className="">
                <Typography
                  className="fw-700 px-3 offcampus-page-title "
                  lineHeight={1}
                >
                  {OFFCAMPUS_PROGRAMS._title_}
                </Typography>
                <Typography className="fs-16 fw-400 mt-4 campus-prog-text px-3">
                  {OFFCAMPUS_PROGRAMS._subTitle_}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={0}
              sm={5}
              sx={{
                display: { xs: "none !important", sm: "block !important" },
              }}
            >
              <Grid
                item
                xs={12}
                className="d-flex justify-content-end align-items-center p-2"
              >
                <AboutCareerCard
                  image={ELIP}
                  text="Experiential Learning Internship Program"
                ></AboutCareerCard>
              </Grid>
              <Grid
                item
                xs={0}
                className="d-flex justify-content-center align-items-center p-2"
              >
                <AboutCareerCard
                  image={PDP}
                  text="Professional Development Program"
                ></AboutCareerCard>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {OFFCAMPUS_PROGRAMS_CONTENT.map(
        ({ id, title, description, image, tabsData, buttonLabel }, index) => (
          <ProgramCard
            key={id}
            title={title}
            description={description}
            image={`${EDIFY_S3_URL}/${image}`}
            tabsData={tabsData}
            buttonLabel={buttonLabel}
            index={index}
            isLast={index === OFFCAMPUS_PROGRAMS_CONTENT.length - 1}
            onBtnClick={() => handleButtonClick(buttonLabel)}
          />
        ),
      )}
    </>
  );
}

export default OffCampusPrograms;
