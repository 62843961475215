import { useEffect, useState } from "react";
import InputBoxComponent from "../../../atoms/InputBoxComponent";
import SimpleDropdown from "../../../atoms/SimpleDropDownComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { Button } from "react-bootstrap";
import ChipComponent from "../../../atoms/ChipComponent";
import ButtonComponent from "../../../atoms/ButtonComponent/ButtonComponent";
import CVfrom from "../../../molecules/CVfrom";
import SubmitForm from "../../../molecules/SubmitForm";
import { postJobData } from "../../../../services/career_jobs/postJobData";
import validateMessage from "../../../../constants/validateMessages";
import validationRegex from "../../../../services/utils/regexUtils";

import CAREERS_DATA from "../../../../constants/en.json";
import {COLORS} from "../../../../constants/colorConstants"
const {GRAY_2, PRIMARY,  BLUE_3,BLUE_4}=COLORS;

const { FULL_TIME, APPLY_NOW } = CAREERS_DATA;

const jobTitles = [
  {
    id: "FullStack Developer",
    label: "FullStack Developer",
  },
  {
    id: "UI/UX designer",
    label: "UI/UX designer",
  },
  {
    id: "FrontEnd Developer",
    label: "FrontEnd Developer",
  },
  {
    id: "BackEnd Developer",
    label: "BackEnd Developer",
  },
];

function OpenPosition({ positionType = "", positionData = [] }) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    email: "",
    resume: "",
  });

  const [firstNameError, setFirstNameErrorMsg] = useState("");
  const [lastNameError, setLastNameErrorMsg] = useState("");
  const [emailError, setEmailErrorMsg] = useState("");
  const [jobTitleError, setJobTitleErrorMsg] = useState("");
  const [resumeError, setResumeErrorMsg] = useState("");
  const [applicationMessage, setApplicationMessage] = useState("");

  let handelChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const validateFirstName = () => {
    let isFirstNameError = true;
    if (formData?.firstName === "") {
      isFirstNameError = false;
      setFirstNameErrorMsg(validateMessage?.field_required);
    } else if (formData?.firstName.length > 30) {
      isFirstNameError = false;
      setFirstNameErrorMsg(`${validateMessage?.maxLength}`);
    } else if (!validationRegex?.name.test(formData?.firstName)) {
      isFirstNameError = false;
      setFirstNameErrorMsg(validateMessage?.alphabets);
    } else {
      isFirstNameError = true;
      setFirstNameErrorMsg("");
    }
    return isFirstNameError;
  };

  const validateLastName = () => {
    let isJobTitleValid = true;
    if (formData?.lastName === "") {
      isJobTitleValid = false;
      setLastNameErrorMsg(validateMessage?.field_required);
    } else if (formData?.lastName.length > 30) {
      isJobTitleValid = false;
      setLastNameErrorMsg(`${validateMessage?.maxLength}`);
    } else if (!validationRegex?.name.test(formData?.lastName)) {
      isJobTitleValid = false;
      setLastNameErrorMsg(validateMessage?.alphabets);
    } else {
      isJobTitleValid = true;
      setLastNameErrorMsg("");
    }
    return isJobTitleValid;
  };

  const validateEmail = () => {
    let isEmailValid = true;
    if (formData?.email === "") {
      isEmailValid = false;
      setEmailErrorMsg(validateMessage.field_required);
    } else if (formData?.email.length > 30) {
      isEmailValid = false;
      setEmailErrorMsg(`${validateMessage?.maxLength}`);
    } else if (!validationRegex.email.test(formData?.email)) {
      isEmailValid = false;
      setEmailErrorMsg(validateMessage.email);
    } else {
      isEmailValid = true;
      setEmailErrorMsg("");
    }
    return isEmailValid;
  };

  const validateJobTitle = () => {
    let isJobTitleValid = true;
    if (formData?.jobTitle === "") {
      isJobTitleValid = false;
      setJobTitleErrorMsg(validateMessage?.field_required);
    } else {
      isJobTitleValid = true;
      setJobTitleErrorMsg("");
    }
    return isJobTitleValid;
  };

  const validateResume = () => {
    let isResumeValid = true;
    if (!formData?.resume) {
      isResumeValid = false;
      setResumeErrorMsg(validateMessage?.field_required);
    } else {
      isResumeValid = true;
      setResumeErrorMsg("");
    }
    return isResumeValid;
  };

  const inputsFields = [
    {
      filed: (
        <InputBoxComponent
          placeholder="Enter First Name"
          textLabel="First Name"
          name="firstName"
          value={formData.firstName}
          required={true}
          onChange={handelChange}
          onBlur={validateFirstName}
          fullWidth={true}
          errorText={firstNameError}
        />
      ),
    },
    {
      filed: (
        <InputBoxComponent
          placeholder="Enter Last Name"
          textLabel="Last Name"
          name="lastName"
          value={formData.lastName}
          required={true}
          onChange={handelChange}
          onBlur={validateLastName}
          fullWidth={true}
          errorText={lastNameError}
        />
      ),
    },
    {
      filed: (
        <SimpleDropdown
          placeholder="Job Title"
          textLabel="Job Title "
          name="jobTitle"
          disableClearable="true"
          options={jobTitles}
          value={
            jobTitles?.find((option) => option?.id === formData?.jobTitle) || ""
          }
          onChange={(subject) => {
            setFormData({
              ...formData,
              jobTitle: subject?.label,
            });
          }}
          onBlur={validateJobTitle}
          required={true}
          fullWidth={true}
          errorText={jobTitleError}
        />
      ),
    },
    {
      filed: (
        <InputBoxComponent
          placeholder="Enter Email ID"
          textLabel="Email ID"
          name="email"
          value={formData.email}
          required={true}
          onChange={handelChange}
          onBlur={validateEmail}
          fullWidth={true}
          errorText={emailError}
        />
      ),
    },
  ];

  const handleResume = (files) => {
    const file = files[0];

    if (file.type !== "application/pdf") {
      setResumeErrorMsg(validateMessage?.pdfFiles);
      return;
    }

    const fileSizeMB = file.size / (1024 * 1024);
    if (fileSizeMB > 5) {
      setResumeErrorMsg(validateMessage?.resumeSize);
      return;
    }

    setResumeErrorMsg("");
    setFormData({ ...formData, resume: file });
  };

  const handleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/pdf";
    input.style.display = "none";
    input.onchange = (event) => {
      const files = event.target.files;
      if (files.length) {
        handleResume(files);
      }
    };
    document.body.appendChild(input);
    input.click();
    document.body.removeChild(input);
  };

  let resumeField = (
    <>
      <Grid mb="3px" px="6px">
        <Typography className="ff-Ro fs-14 fw-600 resume-text">
          Resume
          <span className="text-danger ms-1">*</span>
        </Typography>
      </Grid>
      <FormControl fullWidth>
        <Button
          variant="outlined"
          onClick={handleClick}
          onChange={(e) => handleResume(e.target.files)}
          style={{
            height: "40px",
            borderColor: GRAY_2,
            color:  PRIMARY,
            backgroundColor:   BLUE_3,
            "&:hover": {
              borderColor: BLUE_4,
              backgroundColor:   BLUE_3,
            },
          }}
        >
          Upload Your resume
        </Button>

        {resumeError ? (
          <FormHelperText className="fw-700 resume-error  ">
            {resumeError}
          </FormHelperText>
        ) : (
          <FormHelperText className="text-primary fw-70">
            {formData.resume ? formData.resume.name : ""}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );

  const useResponsiveWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (width >= 768) {
      return "60vw";
    } else {
      return "90vw";
    }
  };

  const useResponsiveSubWidth = () => {
    const [subwidth, setsubwidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setsubwidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (subwidth >= 768) {
      return "60vw";
    } else {
      return "90vw";
    }
  };

  const Subwidth = useResponsiveSubWidth();

  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "90%",
      sm: "80%",
      md: "70%",
      lg: "60%",
      xl: "50%",
    },
    maxHeight: "100vh",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const SubModelstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { Subwidth },
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const [applyModel, setapplyModel] = useState(false);
  const [submodel, setsubmodel] = useState(false);

  const mainModelOpen = () => setapplyModel(true);

  const mainModelClose = () => {
    setapplyModel(false);
    handleClearForm();
    setFirstNameErrorMsg("");
    setLastNameErrorMsg("");
    setJobTitleErrorMsg("");
    setEmailErrorMsg("");
    setResumeErrorMsg("");
  };

  const handleSubmit = () => {
    let isFirstNameValid = validateFirstName();
    let isLastNameValid = validateLastName();
    let isEmailValid = validateEmail();
    let isJobTitleValid = validateJobTitle();
    let isResumeValid = validateResume();

    if (
      isFirstNameValid &&
      isLastNameValid &&
      isEmailValid &&
      isJobTitleValid &&
      isResumeValid
    ) {
      submitFormData();
      setFirstNameErrorMsg("");
      setLastNameErrorMsg("");
      setJobTitleErrorMsg("");
      setEmailErrorMsg("");
      setResumeErrorMsg("");
    }
  };
  const handleClearForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      jobTitle: "",
      email: "",
      resume: "",
    });
  };

  const submitFormData = async () => {
    let payload = new FormData();
    payload.append("firstName", formData.firstName);
    payload.append("lastName", formData.lastName);
    payload.append("jobTitle", formData.jobTitle);
    payload.append("email", formData.email);
    payload.append("resume", formData.resume);
    let { data} = await postJobData(payload);
    if (data) {
      if (!data?.error) {
        setapplyModel(false);
        setApplicationMessage("Your application is submitted successfully");
        setsubmodel(true);
      } else {
        setapplyModel(false);
        setApplicationMessage("Your application is not submitted");
        setsubmodel(true);
      }
    } else {
      setapplyModel(false);
      setApplicationMessage("something went wrong please try again");
      setsubmodel(true);
    }
    handleClearForm();
  };

  const SubModelClose = () => setsubmodel(false);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (formData.resume) {
      validateResume();
    }
  }, [formData.resume]);

  return (
    <>
      <Grid container className="">
        <Grid item xs={12}>
          <Typography
            className="fw-700 fs-30 lh-lg"
            color={"rgba(25, 24, 37, 1)"}
          >
            {positionType}
          </Typography>
        </Grid>
        <Grid item container xs={12} className="p-3" marginBottom={6}>
          {positionData.map((item, idx) => (
            <Accordion
              expanded={expanded === "panel" + idx + 1}
              onChange={handleChange("panel" + idx + 1)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Grid
                  container
                  key={idx}
                  mt={2.5}
                  padding={1}
                  borderBottom={!expanded ? "" : `1px solid ${BLUE_3}`}
                  className="d-flex justify-content-center"
                >
                  <Grid item xs={12} mb={1} alignContent={"center"}>
                    <Typography className="fw-700 fs-18 text-align">
                      {item.jobPosition}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    mb={1}
                    className="text-align justify-content-center align-items-center"
                  >
                    <ChipComponent
                      label={FULL_TIME}
                      index={idx}
                      chipMuiProps="content-chip1"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    mb={1}
                    className="text-align justify-content-center align-items-center"
                  >
                    <Typography className=" fs-14">
                      {item.jobExperience}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} mb={1} className="text-align">
                    <ButtonComponent
                      label={APPLY_NOW}
                      fullWidth={false}
                      bgColor="bg-info"
                      onBtnClick={mainModelOpen}
                    />
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {item.JobDetails.map((detail, idx) => (
                    <>
                      <Grid item sm={3} xs={12} key={idx}>
                        <Typography className=" fw-700 fs-18 ">
                          {detail.title}
                        </Typography>
                      </Grid>
                      <Grid item sm={9} xs={12}>
                        {detail.deatils.length > 1 ? (
                          detail.deatils.map((list, index) => (
                            <ul className="list-style-square">
                              <li>
                                <Typography
                                  key={index}
                                  className=" fw-400 fs-14"
                                >
                                  {list.list}
                                </Typography>
                              </li>
                            </ul>
                          ))
                        ) : (
                          <Typography className=" fw-400 fs-14 my-2">
                            {detail.deatils[0].list}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>

      <Modal
        open={applyModel}
        onClose={(e, reason) => {
          reason !== "backdropClick" && setapplyModel(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} onClick={(e) => e.stopPropagation()}>
          <Grid container>
            <Grid item xs={12}>
              <CVfrom
                onSubmit={handleSubmit}
                onModelClose={mainModelClose}
                inputsFields={inputsFields}
                message={resumeField}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={submodel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={SubModelstyle}>
          <Grid container>
            <Grid item xs={12}>
              <SubmitForm
                onSubModelClose={SubModelClose}
                message={applicationMessage}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default OpenPosition;
