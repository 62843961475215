import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AboutCareerCard from "../../molecules/cards/AboutCareerCard";

import LEARNING_CONTENT_DATA from "../../../constants/en.json";
import ProgramCard from "../../molecules/programCard/ProgramCard";
import CAMPUS_PROGRAMS_CONTENT from "../../../constants/ProgramsTabsData/cipTabsData";
const { CAMPUS_PROGRAMS } = LEARNING_CONTENT_DATA;

let S3_URL = process.env.REACT_APP_DYASHIN_S3_URL;
let EDIFY_S3_URL = process.env.REACT_APP_DSEDIFY_S3_URL;

let CIP = S3_URL + `/assets/services/Group 26079.png`;
let CPP = S3_URL + `/assets/services/Group 21973@2x.png`;
let FMP = S3_URL + `/assets/services/Group 23877@2x.png`;

let TCP = EDIFY_S3_URL+"/assetsWebp/campusPrograms/TCP.webp";
let SSP = EDIFY_S3_URL+"/assetsWebp/campusPrograms/SSP.webp";
let FDP = EDIFY_S3_URL+"/assetsWebp/campusPrograms/FDP.webp";
function CampusPrograms() {
  const { section } = useParams();

  useEffect(() => {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        const yOffset = -60;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [section]);

  let navigate = useNavigate();

  const handleButtonClick = (buttonLabel) => {
    if (buttonLabel === "Enroll") {
      navigate("/enquiry");
    } else if (buttonLabel === "Contact Us") {
      navigate("/contact-us");
    }
  };

  return (
    <>
      <Box>
        <Box className="py-5 darkBlueBackground" id="campus-programs">
          <Grid container rowGap={2} rowSpacing={2}>
            <Grid item md={1}></Grid>
            <Grid item xs={12} sm={12} md={3.5} lg={4} className="p-2">
              <Box>
                <Typography className="fw-700 page-title px-2" lineHeight={1}>
                  {CAMPUS_PROGRAMS._title_}
                </Typography>
                <Typography className="fs-16 fw-400 mt-4 campus-prog-text px-2">
                  {CAMPUS_PROGRAMS._subTitle_}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              container
              rowSpacing={2}
              xs={0}
              md={6.5}
              className="ms-5 d-grid gap-3 "
              sx={{
                display: { xs: "none !important", sm: "block !important" },
              }}
            >
              <Grid
                item
                xs={0}
                md={11}
                lg={10}
                className="d-flex justify-content-start align-items-end gap-3"
              >
                <AboutCareerCard
                  image={CIP}
                  text="Campus Internship Program"
                ></AboutCareerCard>
                <AboutCareerCard
                  image={CPP}
                  text=" Campus Project Program"
                ></AboutCareerCard>
                <AboutCareerCard
                  image={TCP}
                  text="Tech Connect Program"
                ></AboutCareerCard>
              </Grid>
              <Grid
                item
                xs={0}
                md={11}
                lg={10}
                className="d-flex justify-content-start align-items-center gap-3"
                ml={2}
              >
                <AboutCareerCard
                  image={SSP}
                  text="Student Success Program "
                ></AboutCareerCard>
                <AboutCareerCard
                  image={FMP}
                  text="Faculty Mirroring Program"
                ></AboutCareerCard>
                <AboutCareerCard
                  image={FDP}
                  text="Faculty Development Program"
                ></AboutCareerCard>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {CAMPUS_PROGRAMS_CONTENT.map(
          ({ id, title, description, image, tabsData, buttonLabel }, index) => (
            <ProgramCard
              key={id}
              title={title}
              description={description}
              image={`${EDIFY_S3_URL}/${image}`}
              tabsData={tabsData}
              buttonLabel={buttonLabel}
              index={index}
              isLast={index === CAMPUS_PROGRAMS_CONTENT.length - 1}
              onBtnClick={() => handleButtonClick(buttonLabel)}
            />
          ),
        )}
      </Box>
    </>
  );
}

export default CampusPrograms;
