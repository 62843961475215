import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ContentSection from "../../../../molecules/ContentSection";
import TabsCard from "../../../../molecules/TabsCard";
import { TABS_DATA } from "../../../../../constants/eSystem";
const S3_URL = process.env.REACT_APP_DSEDIFY_S3_URL;
const background = S3_URL + "/assets/technology/embeddedSystems.png";

function ESystem() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <>
      <Grid
        container
        paddingTop={10}
        className="es-system "
        sx={{
          backgroundImage: `url(${background})`,
        }}
      >
        <Grid item xs={2}></Grid>
        <Grid item container xs={8}>
          <ContentSection
            title="Embedded Systems"
            titleMuiProp="text-white"
            badgeBgColor="bg-light"
            badgeTextColor="rgba(36, 171, 227, 1) "
            className="text-color"
          >
            {TABS_DATA.map((val, idx) => (
              <Grid item xs={3} paddingX={2}>
                <TabsCard
                  key={idx}
                  icon={val.icon}
                  index={idx}
                  iconContent={val.iconContent}
                  onCardClick={(index) => {
                    setSelectedIndex(index);
                  }}
                  selected={selectedIndex === idx}
                />
              </Grid>
            ))}
          </ContentSection>
        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>
      <Grid container className="mt-4">
        <Grid item xs={2}></Grid>
        <Grid
          item
          container
          xs={8}
          className="esystem-main"
          alignContent="center"
        >
          <Grid item xs={12}>
            <Typography className="fw-400 fs-16 txt-description p-1 esystem-desc">
              {TABS_DATA[selectedIndex].description}
            </Typography>
          </Grid>

          <Grid item xs={2}></Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ESystem;
