import moment from "moment";

function getUpcomingBatchDates() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  let next15thDate = new Date(currentYear, currentMonth, 15);
  if (currentDate > next15thDate) {
    next15thDate.setMonth(next15thDate.getMonth() + 1);
  }

  let next1stDate = new Date(currentYear, currentMonth + 1, 1);
  if (currentDate >= next1stDate) {
    next1stDate.setMonth(next1stDate.getMonth() + 1);
  }

  if (next1stDate.getDay() === 0) {
    next1stDate.setDate(next1stDate.getDate() + 1);
  } else if (next1stDate.getDay() === 6) {
    next1stDate.setDate(next1stDate.getDate() + 2);
  }

  if (next15thDate.getDay() === 0) {
    next15thDate.setDate(next15thDate.getDate() + 1);
  } else if (next15thDate.getDay() === 6) {
    next15thDate.setDate(next15thDate.getDate() + 2);
  }

  let upcomingDate;
  if (currentDate >= next1stDate) {
    upcomingDate = moment(next15thDate).format("Do MMM YYYY");
  } else {
    upcomingDate = moment(next1stDate).format("Do MMM YYYY");
  }

  return upcomingDate;
}

const upcomingBatchDate = getUpcomingBatchDates();

function formattedTimeData(time) {
  return moment(time, "HH:mm:ss.SSS").format("HH:mm:ss.SSS");
}

const getTimeformatted = (value) => {
  return moment(new Date(value)).format("HH:mm:ss");
};

const getDateformatted = (value) => {
  return moment(value).format("YYYY-MM-DD");
};

const getTimeInDatePickerFormat = (value) => {
  return moment(new Date().toDateString() + " " + value).format(
    "YYYY-MM-DDTHH:mm:ss",
  );
};

export {
  formattedTimeData,
  getTimeformatted,
  getTimeInDatePickerFormat,
  getDateformatted,
  getUpcomingBatchDates,
};
