import { Grid } from "@mui/material";
import React from "react";
import JobVacancyPage from "./JobVacancyPage";
import OpenPosition from "./OpenPosition";
import JoinOurTeamCard from "./JobVacancyPage/JoinOurTeamCard";

function Careers() {
  return (
    <>
      <Grid container>
        <JoinOurTeamCard />
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <JobVacancyPage />
          <OpenPosition />
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </>
  );
}

export default Careers;
