import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import TabsCardComponent from "../../../components/molecules/TabsCardComponent/TabsCardComponent";
import renderDangersoulyHTML from "../../../commonFunction/renderDangersoulyHTML";
import {COLORS} from "../../../constants/colorConstants"
const { BLUE_3}=COLORS;

function ProgramsTabComponent({
  tabsData = [
    {
      iconContent: "",
      description: "",
      details: [{ label: "", subDescription: "" }],
    },
  ],
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <>
      <Grid container>
        <Grid
          item
          className="w-100 d-flex justify-content-center align-items-center"
        >
          {tabsData?.map((val, idx) => (
            <Box className="px-2" key={idx}>
              <TabsCardComponent
                icon={val?.icon}
                index={idx}
                iconContent={val?.iconContent}
                sxcard={{
                  borderTop: "2px solid",
                  borderImageSource:
                    "linear-gradient(90deg, #FA921F 0%, #D3D2AE 50%, #24ABE3 100%)",
                  borderImageSlice: 1,
                  filter: "",
                  boxShadow: "0px 10px 60px rgba(25, 24, 37, 0.08)",
                  borderRadius: "3px 3px 0px 0px",
                }}
                onCardClick={(index) => {
                  setSelectedIndex(index);
                }}
                selected={selectedIndex === idx}
              />
            </Box>
          ))}
        </Grid>
        <Grid
          item
          className="mt-2 d-flex justify-content-center"
          width={"100%"}
        >
          <Box
            width={{ xs: "90%", sm: "90%", md: "76%" }}
            bgcolor={ BLUE_3}
          >
            <Box
              sx={{
                overflowY: "auto",
              }}
              paddingY={3}
            >
              <Typography className="fw-400 fs-18 h-100 text-start px-3">
                {tabsData?.[selectedIndex]?.description}
              </Typography>
              {Array.isArray(tabsData?.[selectedIndex]?.details) ? (
                selectedIndex === 0 ? (
                  <div className="eligibility-criteria">
                    {tabsData?.[selectedIndex]?.details?.map(
                      (detail, index) => (
                        <Box key={index} className="eligibility-item">
                          <ul>
                            <li className="fs-18 fw-700">{detail?.label}</li>
                            <p className="fs-14 fw-300 p-2">
                              {renderDangersoulyHTML(detail?.subDescription)}
                            </p>
                          </ul>
                        </Box>
                      )
                    )}
                  </div>
                ) : (
                  tabsData?.[selectedIndex]?.details?.map((detail, index) => (
                    <Box key={index} className="d-flex">
                      <ul>
                        <li className="fs-18 fw-700">{detail?.label}</li>
                        <p className="fs-14 fw-300 ml-2">
                          {renderDangersoulyHTML(detail?.subDescription)}
                        </p>
                      </ul>
                    </Box>
                  ))
                )
              ) : (
                <>{tabsData[selectedIndex].details}</>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ProgramsTabComponent;
