const S3_URL = process.env.REACT_APP_DSEDIFY_S3_URL;
let designTools = S3_URL + "/assets/technology/designTools.png";
let SimulationTool = S3_URL + "/assets/technology/simulationTools.png";

export let CARD_DATA = [
  {
    heading: "Design Tools",
    description:
      "We showcase our advanced capabilities and expertise in design tools, leveraging platforms such as CATIA, SolidWorks, Inventor, Creo, and NxCAD. Our skilled professionals are adept at handling diverse design requirements, ensuring precision and innovation. Whether it's complex engineering designs or product development, we harness the power of these cutting-edge tools to deliver solutions that meet the highest industry standards. With Dyashin, you can trust that your projects are in the hands of proficient experts, ready to transform your ideas into reality using the latest and most advanced design technologies available.",
    softwareIcons: designTools,
  },
  {
    heading: "Simulation & Analysis Tools",
    description:
      "We showcase unparalleled competence in Simulation & Analysis Tools, utilizing cutting-edge software such as SolidWorks, HyperWorks, Abaqus, NxNastran, LS-Dyna, FeSafe, FEMFAT. Our adept team ensures precision, reliability, and innovative solutions for diverse engineering challenges. With an unwavering commitment to excellence, Dyashin is well-equipped to address any requirement in this technology, providing clients with advanced simulations and analyses that propel their projects to new heights. Trust Dyashin Technosoft for comprehensive expertise and unparalleled proficiency in Simulation & Analysis Tools, delivering solutions that meet the highest standards of accuracy and efficiency in the ever-evolving engineering landscape.",
    softwareIcons: SimulationTool,
  },
];
