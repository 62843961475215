"use client";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  TextField,
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormHelperText } from "@mui/material";
import { IoMdArrowDropdown } from "react-icons/io";
import { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      fontSize: "0.875rem",
    },
  },
  input: {
    color: "#B3B3B3",
  },
  clearIndicator: {
    color: "red",
  },
  optionStyle: {
    display: "flex",
    margin: "0px 5px",
    padding: "0px 5px",
    borderBottom: "0.5px solid #DDEDF4",
    cursor: "pointer",
    "&:hover": {
      background: "#9FCCE066 !important",
    },
  },
  listBox: {
    border: "1px solid #9FCCE0 !important",
    borderRadius: "8px !important",
    marginTop: "3px",
  },
  tagTextStyle: {
    maxWidth: "calc(100% - 45px)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
  },
});

const commonStyles = {
  bgcolor: "background.paper",
  m: 0.2,
  borderColor: "grey.500",
  height: "1.2rem",
};
const MultiSelectDropDown = ({
  id = "simple-drop-down",
  value = [],
  options = [],
  fullWidth = false,
  label = "",
  variant = "outlined",
  errorText = "",
  placeholder = "-- select --",
  onChange = () => {},
  onBlur = () => {},
  readOnly = false,
  disabled = false,
  size = "small",
  sx = {},
  textLabel = "",
  endAdornmentLine = true,
  required = false,
  limitTags = 2,
  renderChipTag = false,
  selectAllLabel = "Select All",
  showSelectAll = false,
}) => {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = useState(value);
  const allSelected = options.length === selectedOptions.length;

  useEffect(() => {
    if (value.length) {
      setSelectedOptions(value);
    }
  }, [value]);
  const renderOption = (props, option, selected) => {
    const selectAllProps =
      option.id === "select-all" ? { checked: allSelected } : {};
    return (
      <Box {...props} className={classes.optionStyle}>
        <FormControlLabel
          onClick={(e) => {
            e.preventDefault();
          }}
          control={
            <Checkbox
              checked={selected}
              size="medium"
              sx={{
                color: "#1181B2",
                "&.Mui-checked": {
                  color: "#0F6F9A",
                },
              }}
              {...selectAllProps}
            />
          }
          sx={{
            "& .MuiFormControlLabel-label": {
              color: "#4a4a4a",
              fontSize: "0.875rem",
              fontWeight: "400",
            },
          }}
          label={option.label}
        ></FormControlLabel>
      </Box>
    );
  };

  const renderTag = (option = []) => {
    const values = [];
    option.map((item) => {
      values.push(item.label);
    });
    return (
      <Typography className={classes.tagTextStyle}>
        {values.join(", ")}
      </Typography>
    );
  };

  const filter = createFilterOptions();

  const handleClearOptions = () => setSelectedOptions([]);
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(options);
    } else {
      handleClearOptions();
    }
  };
  const handleToggleSelectAll = () => {
    handleSelectAll(!allSelected);
  };
  const handleToggleOption = (selectedOptions) =>
    setSelectedOptions(selectedOptions);
  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "selectOption" || reason === "removeOption") {
      if (
        selectedOptions.length > options.length &&
        reason === "selectOption"
      ) {
        handleClearOptions();
        return onChange([]);
      } else {
        if (selectedOptions.find((option) => option.id === "select-all")) {
          handleToggleSelectAll();
          let result = [];
          result = options.filter((el) => el.id !== "select-all");
          return onChange(result);
        } else {
          handleToggleOption(selectedOptions);
          return onChange(selectedOptions);
        }
      }
    }
  };
  return (
    <>
      <Grid mb="3px" px="6px">
        <Typography
          sx={{ cursor: "pointer", color: disabled ? "#ccc" : "#1f3763" }}
          className="ff-Ro fs-14 fw-600"
        >
          {textLabel}
          {required && <span className="text-danger ms-1">*</span>}
        </Typography>
      </Grid>
      <Box>
        <Autocomplete
          clearIcon={<></>}
          noOptionsText={<Typography>No options available</Typography>}
          size={size}
          value={showSelectAll ? selectedOptions : value}
          id={id}
          multiple={true}
          limitTags={limitTags}
          options={options}
          disableCloseOnSelect
          renderTags={renderChipTag ? null : (option) => renderTag(option)}
          renderOption={(props, option, { selected }) =>
            renderOption(props, option, selected)
          }
          filterOptions={
            showSelectAll
              ? (options, params) => {
                  const filtered = filter(options, params);
                  return [
                    { label: selectAllLabel, id: "select-all" },
                    ...filtered,
                  ];
                }
              : (options, params) => {
                  const filtered = filter(options, params);
                  return [...filtered];
                }
          }
          fullWidth={fullWidth}
          getOptionLabel={(option) => (option.label ? option.label : "")}
          isOptionEqualToValue={(option, value) =>
            value === undefined || value === "" || option.id === value.id
          }
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              error={Boolean(errorText)}
              onBlur={onBlur}
              placeholder={value.length > 0 ? "" : placeholder}
              classes={{ root: classes.customTextField }}
            />
          )}
          onChange={
            showSelectAll
              ? handleChange
              : (_, value) => {
                  onChange(value);
                }
          }
          readOnly={readOnly}
          sx={{
            "& .MuiInputBase-input": {
              ...commonStyles,
            },
            "& .MuiOutlinedInput-root:hover": {
              "& > fieldset": {
                borderColor: "#1181b2",
                borderRadius: "6px",
                backgroundColor: "#8dabe142",
              },
            },
            "& .MuiOutlinedInput-root:focus": {
              "& > fieldset": {
                outline: "none",
                borderColor: "#A6A6A6",
                borderRadius: "6px",
                backgroundColor: "#8dabe142",
              },
            },

            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: "#A6A6A6",
                borderRadius: "6px",
                backgroundColor: "#8dabe142",
              },
            },

            "& .MuiAutocomplete-endAdornment": {
              borderLeft: endAdornmentLine ? "1px solid #A6A6A6" : "",
            },
            ...sx,
          }}
          classes={{ paper: classes.listBox }}
          popupIcon={
            <>
              <IoMdArrowDropdown
                style={{
                  color: "#A6A6A6",
                }}
              />
            </>
          }
        />
      </Box>
      {errorText && (
        <FormHelperText error className="fw-700">
          {errorText}
        </FormHelperText>
      )}
    </>
  );
};

export default MultiSelectDropDown;
