import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Typography } from "@mui/material";
import ButtonComponent from "../../atoms/ButtonComponent/ButtonComponent";
import CONTENT_DATA from "../../../constants/en.json";

const { _CvDescription_ } = CONTENT_DATA;

function CVForm({
  message = "",
  onSubmit = () => {},
  onModelClose = () => {},
  inputsFields = [],
  showcloseIcon = true,
  heading = "Send Us Your",
  subheading = "CV",
}) {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 d-flex justify-content-center">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="d-md-none">
                  {showcloseIcon && (
                    <div className="d-flex justify-content-end p-2">
                      <Button
                        onClick={onModelClose}
                        className="btn-hover-background"
                        disableRipple
                      >
                        <CloseIcon className="closeicon-color" />
                      </Button>
                    </div>
                  )}
                  <Typography className="fw-700 fs-md-45 fs-xs-26 text-center my-1">
                    {heading}{" "}
                    <span className="chip-title-blue">{subheading}</span>
                  </Typography>
                </div>
                <div className="d-none d-md-block">
                  <div className="row">
                    <div className="col-11">
                      <Typography className="fw-700 fs-45 text-center my-1">
                        {heading}{" "}
                        <span className="chip-title-blue">{subheading}</span>
                      </Typography>
                    </div>
                    {showcloseIcon && (
                      <div className="col-1 d-flex justify-content-end p-2">
                        <Button
                          onClick={onModelClose}
                          className="btn-hover-background"
                          disableRipple
                        >
                          <CloseIcon className="closeicon-color" />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {inputsFields.map((val, idx) => (
            <div
              key={idx}
              className="col-10 col-md-5 d-flex flex-column m-md-2 m-xs-1"
            >
              {val.filed}
            </div>
          ))}
        </div>
        <div className="col-10 mt-2">{message}</div>
        <div className="col-12">
          <Typography className="text-center fw-400 fs-12 txt-description mt-3">
            {_CvDescription_}
          </Typography>
        </div>
        <div className="col-12 d-flex justify-content-center mt-4 mb-4">
          <ButtonComponent
            label="Submit"
            fullWidth={false}
            size="large"
            bgColor="bg-info"
            className="px-3 py-2"
            onBtnClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default CVForm;
