import ilpTabsData from "../ProgramsTabsData/ilpTabsData";
import vlpTabsData from "../ProgramsTabsData/vlpTabsData";
import LEARNING_CONTENT_DATA from "../en.json";

const {
  CORPORATE_PROGRAMS_DATA,
  Elp_What_You_Will_Learn,
  Elp_How_It_Works,
  Eligibility_Criteria,
  How_It_Works,
  What_You_will_learn,
  Elp_Eligibility_Criteria_Description,
} = LEARNING_CONTENT_DATA;

const elpTabsData = [
  {
    iconContent: Eligibility_Criteria,
    description: Elp_Eligibility_Criteria_Description,
  },
  {
    iconContent: How_It_Works,
    details: Elp_How_It_Works,
  },
  {
    iconContent: What_You_will_learn,
    details: Elp_What_You_Will_Learn,
  },
];

const offCampusTabsData = {
  elpTabsData,
  ilpTabsData,
  vlpTabsData,
};

const CORPORATE_PROGRAMS_CONTENT = CORPORATE_PROGRAMS_DATA.map((program) => {
  return {
    id: program._id_,
    title: program._title_,
    description: program._description_,
    image: program._image_,
    buttonLabel: program._buttonLabel_,
    tabsData: offCampusTabsData[program._tabsData_],
  };
});

export default CORPORATE_PROGRAMS_CONTENT;
