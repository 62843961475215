import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import ButtonComponent from "../../atoms/ButtonComponent/ButtonComponent";
import CONTENT_DATA from "../../../constants/en.json";
import {COLORS} from "../../../constants/colorConstants"
const {RED_1,WHITE_3,PRIMARY}=COLORS;

const {
  _ContactSales_,
  _ContactDescriptionFirst_,
  _ContactDescriptionSecond_,
} = CONTENT_DATA;

function ContactModal({ connectModal, setconnectModal }) {
  const [formData, setformData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateField = (name, value) => {
    let error = undefined;

    if (name === "name" && !value.trim()) {
      error = "Name is required";
    }

    if (name === "email" && !value.trim()) {
      error = "Email is required";
    } else if (name === "email" && !emailRegex.test(value)) {
      error = "Invalid email format";
    }

    if (name === "subject" && !value.trim()) {
      error = "Subject is required";
    }

    if (name === "message" && !value.trim()) {
      error = "Message is required";
    }

    return error;
  };

  const updateDetails = (e) => {
    const { name, value } = e.target;

    setformData({
      ...formData,
      [name]: value,
    });

    const error = validateField(name, value);

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  useEffect(() => {
    if (connectModal) {
      setErrors({});
      setformData({ name: "", email: "", subject: "", message: "" });
    }
  }, [connectModal]);
  return (
    <Modal
      show={connectModal}
      onHide={() => {
        setconnectModal(false);
      }}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="text-center" className="text-center w-100">
          <Typography className="fs-20 fw-700 darkBlueTextHeading">
            {_ContactSales_}
          </Typography>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box className="p-2">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Form.Control
                type="text"
                placeholder="Your Full Name"
                name="name"
                value={formData.name}
                onChange={updateDetails}
              />
              {errors.name && <p className="error-message">{errors.name}</p>}
            </Grid>
            <Grid item xs={12}>
              <Form.Control
                type="text"
                placeholder="Your Email Address"
                name="email"
                value={formData.email}
                onChange={updateDetails}
              />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </Grid>
            <Grid item xs={12}>
              <Form.Control
                type="text"
                placeholder="Your Organization Name"
                name="subject"
                value={formData.subject}
                onChange={updateDetails}
              />
              {errors.subject && (
                <p className="error-message">{errors.subject}</p>
              )}
            </Grid>
            <Grid item xs={12}>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter the program name or subscription you are intrested"
                name="message"
                value={formData.message}
                onChange={updateDetails}
              />
              {errors.message && (
                <p className="error-message">{errors.message}</p>
              )}
            </Grid>
          </Grid>
        </Box>
        <Typography className="fs-12 fw-500 text-center">
          {_ContactDescriptionFirst_}
          <br></br>
          {_ContactDescriptionSecond_}
        </Typography>
      </Modal.Body>
      <Modal.Footer>
        <ButtonComponent
          label="Cancel"
          className="fs-12"
          onBtnClick={() => {
            setconnectModal(false);
          }}
          sx={{
            marginRight: "10px",
            backgroundColor: RED_1,
            borderRadius: "20px",
            color: WHITE_3,
            "&:hover": {
              backgroundColor: RED_1,
            },
          }}
        ></ButtonComponent>

        <ButtonComponent
          label="Submit"
          className="fs-12"
          sx={{
            backgroundColor: PRIMARY,
            borderRadius: "20px",
            color: WHITE_3,

            "&:hover": {
              backgroundColor: "BLUE_1",
            },
          }}
          onBtnClick={() => {}}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ContactModal;
