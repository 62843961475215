export const COLORS = {
  PRIMARY: "#1f3763",
  SECONDARY: "#f58008",
  TERNARY: "#019cdc",
  BLUE_1: "#24abe3",
  BLUE_2:"#081d44",
  BLUE_3:"#8DABE142",
  BLUE_4:"#229cd0",
  BLUE_5:"#C9D0DE",
  ORANGE_1:"#F77E0B",
  ORANGE_2:"#FA921F",
 WHITE_1:"#f5f8fb",
WHITE_2:"#fff",
WHITE_3:"white",
WHITE_4:"#ffffff",
GRAY_1:"#A6A6A6",
GRAY_2:"#ccc",
RED_1:"#D63E3E",

};
