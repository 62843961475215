let steps = [
  {
    id: "1",
    message: "Welcome to Dyashin",
    trigger: "2",
  },
  {
    id: "2",
    message: "Please pick a service to continue.",
    trigger: "select-service",
  },
  {
    id: "select-service",
    options: [
      {
        value: "project-services",
        label: "Project Services",
        trigger: "project-services",
      },
      {
        value: "consulting-services",
        label: "Consulting Services",
        trigger: "consulting-services",
      },
      {
        value: "learning-services",
        label: "Learning(training) Services",
        trigger: "learning-services",
      },
    ],
  },
  {
    id: "project-services",
    message: "Would you like to know more about Project Services?",
    trigger: "project-services-name",
  },
  {
    id: "project-services-name",
    message: "Please enter your name.",
    trigger: "ask-name-projects",
  },
  {
    id: "ask-name-projects",
    user: true,
    trigger: "ask-phone-projects",
    validator: (value) => {
      if (!/^[a-zA-Z\s]{1,30}$/.test(value)) {
        return "Use letters only , max 30 char";
      }
      return true;
    },
  },
  {
    id: "ask-phone-projects",
    message:
      "Thank you {previousValue}! Can you please provide your phone number?",
    trigger: "phone-projects",
  },
  {
    id: "phone-projects",
    user: true,
    trigger: "ask-email-projects",
    validator: (value) => {
      if (!/^\+?[1-9]\d{1,14}$/.test(value)) {
        return "Enter a 10-digit or valid international phone number";
      }
      return true;
    },
  },
  {
    id: "ask-email-projects",
    message: "Got it! What is your email address?",
    trigger: "email-projects",
  },
  {
    id: "email-projects",
    user: true,
    trigger: "ask-company-projects",
    validator: (value) => {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        return "Enter a valid email address";
      }
      return true;
    },
  },
  {
    id: "ask-company-projects",
    message: "Do you have a company name?",
    trigger: "company-question-projects",
  },
  {
    id: "company-question-projects",
    options: [
      { value: "yes", label: "Yes", trigger: "ask-company-name-projects" },
      { value: "no", label: "No", trigger: "end-message" },
    ],
  },
  {
    id: "ask-company-name-projects",
    message: "Please provide your company name.",
    trigger: "company-name-projects",
  },
  {
    id: "company-name-projects",
    user: true,
    trigger: "end-message",
  },
  {
    id: "end-message",
    message:
      "Thank you for providing your details. We will get back to you soon!",
    end: true,
  },

  {
    id: "consulting-services",
    message: "Please select the consulting service you are interested in.",
    trigger: "select-consulting-service",
  },
  {
    id: "select-consulting-service",
    options: [
      {
        value: "Permanent Hire",
        label: "Permanent Hire",
        trigger: "consulting-service-selected",
      },
      {
        value: "Contract Hire",
        label: "Contract Hire",
        trigger: "consulting-service-selected",
      },
      {
        value: "Contract to Hire",
        label: "Contract to Hire",
        trigger: "consulting-service-selected",
      },
    ],
  },
  {
    id: "consulting-service-selected",
    message: "Would you like to know more about {previousValue} service?",
    trigger: "consulting-service-name",
  },
  {
    id: "consulting-service-name",
    message: "Please enter your name.",
    trigger: "consulting-name",
  },
  {
    id: "consulting-name",
    user: true,
    trigger: "consulting-ask-phone",
    validator: (value) => {
      if (!/^[a-zA-Z\s]{1,30}$/.test(value)) {
        return "Use letters only , max 30 char";
      }
      return true;
    },
  },
  {
    id: "consulting-ask-phone",
    message:
      "Thank you {previousValue} ! Can you please provide your phone number?",
    trigger: "consulting-phone",
  },
  {
    id: "consulting-phone",
    user: true,
    trigger: "consulting-ask-email",
    validator: (value) => {
      if (!/^\+?[1-9]\d{1,14}$/.test(value)) {
        return "Enter a 10-digit or valid international phone number";
      }
      return true;
    },
  },
  {
    id: "consulting-ask-email",
    message: "Got it! What is your email address?",
    trigger: "consulting-email",
  },
  {
    id: "consulting-email",
    user: true,
    trigger: "consulting-end-message",
    validator: (value) => {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        return "Enter a valid email address";
      }
      return true;
    },
  },
  {
    id: "consulting-end-message",
    message:
      "Thank you for providing your details. We will get back to you soon!",
    end: true,
  },

  {
    id: "learning-services",
    message: "Are you a student?",
    trigger: "student-question",
  },
  {
    id: "student-question",
    options: [
      { value: "yes", label: "Yes", trigger: "student-yes" },
      { value: "no", label: "No", trigger: "professional-options" },
    ],
  },
  {
    id: "student-yes",
    message:
      "Great! These programs are a perfect fit for students. Please select a program.",
    trigger: "student-programs",
  },
  {
    id: "student-programs",
    options: [
      {
        value: "Campus Internship Program",
        label: "Campus Internship Program ",
        trigger: "cip",
      },
      {
        value: "Campus Project Program",
        label: "Campus Project Program",
        trigger: "cpp",
      },
    ],
  },
  {
    id: "professional-options",
    message:
      "Wonderful! These programs are ideally suited for you, Please select a program.",
    trigger: "professional-programs",
  },
  {
    id: "professional-programs",
    options: [
      {
        value: "Experiential Learning Internship Program",
        label: "Experiential Learning Internship Program (4 Months)",
        trigger: "elip",
      },
      {
        value: "Professional Development Program",
        label: "Professional Development Program (12 Months)",
        trigger: "pdp",
      },
    ],
  },
  {
    id: "cip",
    message: "Would you like to know more about {previousValue} ?",
    trigger: "cip-name",
  },
  {
    id: "cpp",
    message: "Would you like to know more about {previousValue} ?",
    trigger: "cpp-name",
  },
  {
    id: "elip",
    message: "Would you like to know more about {previousValue} ?",
    trigger: "elip-name",
  },
  {
    id: "pdp",
    message: "Would you like to know more about {previousValue} ?",
    trigger: "pdp-name",
  },
  {
    id: "cip-name",
    message: "Please enter your name.",
    trigger: "name",
  },
  {
    id: "cpp-name",
    message: "Please enter your name.",
    trigger: "name",
  },
  {
    id: "elip-name",
    message: "Please enter your name.",
    trigger: "name",
  },
  {
    id: "pdp-name",
    message: "Please enter your name.",
    trigger: "name",
  },
  {
    id: "name",
    user: true,
    trigger: "ask-phone",
    validator: (value) => {
      if (!/^[a-zA-Z\s]{1,30}$/.test(value)) {
        return "Use letters only , max 30 char";
      }
      return true;
    },
  },
  {
    id: "ask-phone",
    message:
      "Thank you {previousValue}! Can you please provide your phone number?",
    trigger: "phone",
  },
  {
    id: "phone",
    user: true,
    trigger: "ask-email",
    validator: (value) => {
      if (!/^\+?[1-9]\d{1,14}$/.test(value)) {
        return "Enter a 10-digit or valid international phone number";
      }
      return true;
    },
  },
  {
    id: "ask-email",
    message: "Got it! What is your email address?",
    trigger: "email",
  },
  {
    id: "email",
    user: true,
    trigger: "end-message",
    validator: (value) => {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        return "Enter a valid email address";
      }
      return true;
    },
  },
  {
    id: "end-message",
    message:
      "Thank you for providing your details. We will get back to you soon!",
    end: true,
  },
];

export default steps;
