import React, { Component } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import ChatSteps from "./ChatSteps";
import ChatbotHeader from "./ChatbotHeader";

import {COLORS} from "../constants/colorConstants"
const { WHITE_1,BLUE_1,WHITE_2}=COLORS;
let EDIFY_S3_URL = process.env.REACT_APP_DSEDIFY_S3_URL;

let userAvatar = EDIFY_S3_URL+"/assetsWebp/UserAvatar1.webp";
let ChatBotProfile = EDIFY_S3_URL+"/assetsWebp/botAvatar.webp";
const theme = {
  background:  WHITE_1,
  botBubbleColor: BLUE_1,
  botFontColor: WHITE_2,
  userBubbleColor: WHITE_2,
  userFontColor: BLUE_1,
};
class ChatbotComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conversation: [],
      userInfo: {},
      isChatEnded: false,
    };
  }

  handleEnd = ({ steps, values }) => {
    const storeUserInfo = (userInfo) => {
      this.setState({ userInfo }, () => {});
    };

    if (values[0] === "project-services") {
      const [
        intrestedService,
        userName,
        phNo,
        email,
        hasCompanyName,
        companyName,
      ] = values;
      const userInfo = {
        intrestedService,
        userName,
        phNo,
        email,
        hasCompanyName,
        ...(hasCompanyName === "yes" && { companyName }),
      };
      storeUserInfo(userInfo);
    }

    if (values[0] === "consulting-services") {
      const [intrestedService, serviceSubCategory, userName, phNo, email] =
        values;
      const userInfo = {
        intrestedService,
        serviceSubCategory,
        userName,
        phNo,
        email,
      };
      storeUserInfo(userInfo);
    }

    if (values[0] === "learning-services") {
      const [
        intrestedService,
        isStudent,
        selectedProgram,
        userName,
        phNo,
        email,
      ] = values;
      const userInfo = {
        intrestedService,
        isStudent,
        selectedProgram,
        userName,
        phNo,
        email,
      };
      storeUserInfo(userInfo);
    }
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <ChatBot
          botAvatar={ChatBotProfile}
          userAvatar={userAvatar}
          headerComponent={<ChatbotHeader onClose={this.props.onClose} />}
          steps={ChatSteps}
          handleEnd={this.handleEnd}
        />
      </ThemeProvider>
    );
  }
}

export default ChatbotComp;
