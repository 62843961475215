import { Chip } from "@mui/material";
import React from "react";

function ChipComponent({
  label = "",
  color = "",
  borderColor = "",
  index = "",
  height = 18,
}) {
  return (
    <>
      <Chip
        label={label}
        sx={{ height: height, color: color, borderColor: borderColor }}
        variant="outlined"
        key={index}
      />
    </>
  );
}

export default ChipComponent;
