import { Grid } from "@mui/material";
import React, { useRef } from "react";

function ContactUsMap() {
  const map = useRef();
  const disableScroll = () => {
    map.current.scrolling = "no";
  };
  return (
    <Grid container>
      <Grid item xs={12} className="position-relative">
        <iframe
          ref={map}
          onMouseEnter={() => disableScroll()}
          src="https://www.google.com/maps/d/u/0/embed?mid=1mAuW9znZTGDunMwmHXBVewriDqDGauo&ehbc=2E312F&noprof=1"
          width="100%"
          height="450"
        ></iframe>
        <Grid
          item
          xs={12}
          top={"3px"}
          color={"rgba(141, 171, 225, 1)"}
          className="position-absolute p-3 w-100 color-blue"
        >
          &nbsp; &nbsp;
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContactUsMap;
