import LEARNING_CONTENT_DATA from "../en.json";

const {
  Vlp_What_You_Will_Learn,
  Vlp_How_It_Works,
  Eligibility_Criteria,
  How_It_Works,
  What_You_will_learn,
  Vlp_Eligibility_Criteria_Description,
} = LEARNING_CONTENT_DATA;

const vlpTabsData = [
  {
    iconContent: Eligibility_Criteria,
    description: Vlp_Eligibility_Criteria_Description,
  },
  {
    iconContent: How_It_Works,
    details: Vlp_How_It_Works,
  },
  {
    iconContent: What_You_will_learn,
    details: Vlp_What_You_Will_Learn,
  },
];
export default vlpTabsData;
