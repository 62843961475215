import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardMedia, Grid, Tooltip } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SchoolIcon from "@mui/icons-material/School";
import ButtonComponent from "../../atoms/ButtonComponent/ButtonComponent";
import { COLORS } from "../../../constants/colorConstants";
import CONTENT_DATA from "../../../constants/en.json";

const { BLUE_1, BLUE_2 } = COLORS;
const { _Any_Graduates_, _Enroll_ } = CONTENT_DATA;

export default function CardComponent({
  image = {},
  title = "",
  description = "",
  enrollBtnClk = () => {},
  courseFee = "",
}) {
  return (
    <Card className="">
      <CardMedia
        component="img"
        image={image}
        alt="Image not Available"
        className="p-2"
        sx={{
          width: "100%",
          height: "200px",
          objectFit: "cover",
        }}
      />

      <CardContent>
        <Grid container>
          <Grid
            item
            className="d-flex justify-content-start align-items-center gap-3"
            xs={12}
          ></Grid>
          <Grid item xs={12}>
            <Tooltip title={title} placement="top">
              <Typography className="fw-700 fs-16 mt-2 our-batches">
                {title}
              </Typography>
            </Tooltip>

            <Tooltip title={description} placement="bottom">
              <Typography className="fs-14 fw-300 mt-2 truncate-text truncate-text-twoline overflow-hidden">
                {description}
              </Typography>
            </Tooltip>
          </Grid>

          <Grid
            item
            xs={12}
            className="d-flex justify-content-between align-items-center gap-1 mt-2"
          >
            <Box className="d-flex align-items-center gap-2">
              <SchoolIcon className="event-icon" />
              <Typography className="fs-12 fw-600">
                {_Any_Graduates_}
              </Typography>
            </Box>
            <Box className="d-flex align-items-center gap-2">
              <CurrencyRupeeIcon className="event-icon" />
              <Typography className="fs-12 fw-600">{courseFee}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} className="gap-3 mt-3 py-2">
            <ButtonComponent
              label={_Enroll_}
              fullWidth
              sx={{
                backgroundColor: BLUE_2,
                "&:hover": {
                  backgroundColor: BLUE_1,
                },
              }}
              onBtnClick={enrollBtnClk}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
