import { Box, Grid, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import StarComponent from "../StarComponent";
import { useState } from "react";
import { COLORS } from "../../../constants/colorConstants";
const {BLUE_1} = COLORS;
let AVATAR_ICON = process.env.REACT_APP_DYASHIN_S3_URL;
let icon = AVATAR_ICON + "/v3/assests/home/testimonialImage1.png ";


const quatos =
  AVATAR_ICON +
  `/v3/assests/home/testimonial-3-quote-icon.b53cb92b.png (1).png`;

export default function WhatPeopleSaysCardComponent({
  description = "",
  iconImage = icon,
  personName = "Christine Rose",
  personDesignation = "Customer",
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <Box padding={3} className="testimonials-content">
      <Grid container bgcolor={"white"} padding={2} width={"100%"}>
        <Box className="whatpeople-scrollbar">
          <Grid item xs={12}>
            <Typography className="fw-400 fs-14 text-secondary">
              {isExpanded ? description : `${description.substring(0, 150)}...`}
              <Link
                to="#"
                onClick={toggleReadMore}
                className="text-decoration-none"
              >
                {isExpanded ? "Read Less" : "Read More"}
              </Link>
            </Typography>
          </Grid>
        </Box>

        <Grid
          item
          xs={12}
          className="justify-content-between align-items-center mt-2"
          display={{ xs: "grid", sm: "flex", md: "flex", lg: "flex" }}
        >
          <Box className="style-card">
            <Box
              width="50px"
              height="50px"
              padding={0.5}
              border={`1px solid ${BLUE_1}`}
              borderRadius="50%"
              className="d-flex justify-content-center align-items-center"
            >
              <img
                src={iconImage}
                alt="person icon"
                className="h-100 w-100 rounded-circle"
              />
            </Box>
            <Box>
              <Tooltip title={personName} placement="bottom">
                <Typography className="fs-16 fw-600 text-secondary our-batches">
                  {personName}
                </Typography>
              </Tooltip>
              <Typography className="fs-14 fw-400 text-secondary text-start">
                {personDesignation}
              </Typography>
            </Box>
          </Box>
          <Box className="star-quote">
            <StarComponent value={4.5} />
            <img loading="lazy"src={quatos} alt="quote icon" />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          display={{ xs: "flex", sm: "none", md: "none", lg: "none" }}
          justifyContent={{ xs: "space-between" }}
        >
          <StarComponent value={4.5} />
          <img loading="lazy"src={quatos} alt="quote icon" />
        </Grid>
      </Grid>
    </Box>
  );
}
