import React, { useEffect } from "react";
import OpenPosition from "../OpenPosition";

import CAREERS_DATA from "../../../../constants/en.json";

const { Open_Positions, Job_Data } = CAREERS_DATA;

function JobVacancyPage() {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <OpenPosition
        positionType={Open_Positions}
        positionData={Job_Data}
        onBtnclick={(index) => {
        }}
      />
    </>
  );
}

export default JobVacancyPage;
