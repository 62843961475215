import { Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import ContentSection from "../../../../molecules/ContentSection";
import TabsCard from "../../../../molecules/TabsCard";
import { TABS_DATA } from "../../../../../constants/softwareTesting";

function SoftwareTesting() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={1}></Grid>
        <Grid item container xs={10}>
          <ContentSection
            title="Software QA & Testing"
            badgeBgColor="bg-light"
            badgeTextColor="rgba(36, 171, 227, 1)"
            className="text-color"
          >
            <Grid item container xs={12}>
              {TABS_DATA.map((val, idx) => (
                <Grid item xs={2.4} paddingX={1} key={idx}>
                  <TabsCard
                    icon={val.icon}
                    index={idx}
                    iconContent={val.iconContent}
                    sxcard={{
                      borderTop: "2px solid",
                      borderImageSource:
                        "linear-gradient(90deg, #FA921F 0%, #D3D2AE 50%, #24ABE3 100%)",
                      borderImageSlice: 1,
                      filter: "",
                      boxShadow: "0px 10px 60px rgba(25, 24, 37, 0.08)",
                      borderRadius: "3px 3px 0px 0px",
                    }}
                    onCardClick={() => setSelectedIndex(idx)}
                    selected={selectedIndex === idx}
                  />
                </Grid>
              ))}
            </Grid>
          </ContentSection>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <Grid container className="mt-2">
        <Grid item xs={1}></Grid>
        <Grid
          item
          container
          xs={10}
          className="py-2"
          alignContent={"center"}
          bgcolor={"#8DABE142"}
        >
          <Grid item xs={12} md={6} alignContent={"center"}>
            <img
              src={TABS_DATA[selectedIndex].toolsImage}
              alt="software testing image"
              width={"100%"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              className="fw-400 fs-16 p-3 h-100 mt-5"
              textAlign={isMobile ? "center" : "justify"}
            >
              {TABS_DATA[selectedIndex].description}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </>
  );
}

export default SoftwareTesting;
