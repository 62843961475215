export let TABS_DATA = [
  {
    iconContent: "Embedded Systems",
    description: `C, C++, Python & Embedded C
Intel 8051 Microcontroller
ARM7 Microcontrollers
Linux, Shell Scripting
System Programming
UART/ USART, I2C & SPI
Protocols`,
  },
  {
    iconContent: "Embedded IoT ",
    description: `C, C++, Python & Embedded C
Microcontroller & Arduino
Sensors, Signals & Electronics
HTTP, CoAP, MQTT, AMQP, 6LoWPAN
IoT Applications using Raspberry Pi`,
  },
  {
    iconContent: "Automotive Embedded Systems",
    description: `Automotive Sensors and Transducers
Sensor interfacing & CAB Analyzer
RS 232, I2C, SPI, CAN, J1939 Protocols
Python, Misra C, MATLAB`,
  },
  {
    iconContent: "Advanced Embedded Systems",
    description: `C, C++, Python & Embedded C
Linux & RTOS RT-LINUX
Intel 8051 Microcontroller
ARM7 & STM32 Microcontrollers
Peripheral Interface Controller (PIC)
Interfacing with external Hardware
UART/ USART, MQTT, CAN, I2C & SPI Protocols`,
  },
];
