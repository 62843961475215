import { Box, Grid,  Typography } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import ContentSection from "../ContentSection";
import Slider from "react-slick";
import TechnologyChipComponent from "../../atoms/TechnologyChipComponent";


import CONTENT_DATA from "../../../constants/en.json";
import renderDangersoulyHTML from "../../../commonFunction/renderDangersoulyHTML";

const { _chipdata_ } = CONTENT_DATA;
const EDIFY_S3_URL = process.env.REACT_APP_DSEDIFY_S3_URL;
let php = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/backend/php.webp";
let android = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/mobile/android.webp";
let flutter = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/mobile/flutter.webp";
let ionic = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/mobile/ionic.webp";
let reactNative = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/mobile/reactNetive.webp";
let ios = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/mobile/ios.webp";
let aws = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/cloud/aws.webp";
let googleCloud = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/cloud/googleColud.webp";
let microsoftAzure = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/cloud/microsoftAzure.webp";
let xamarin = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/cloud/xamarin.webp";
let odoo = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/erp/odoo.webp";
let sap = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/erp/sap.webp";
let zoho = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/erp/zoho.webp";
let salesforce = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/erp/salesforce.webp";
let powerBI = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/analytics/powerBI.webp";
let qlik = EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/analytics/qlik.welet tableau from "
let tableau=EDIFY_S3_URL+"/assetsWebp/technology/softwareDevelopment/analytics/tableue.webp";

function TechnologySoftwareDevelopment() {
  const [direction, setDirection] = useState(1);
  const sliderRef = useRef(null);

  const handleAfterChange = (current) => {
    const totalSlides = sliderRef.current.props.children.length;
    const visibleSlides = settings.slidesToShow;

    if (direction === 1 && current >= totalSlides - visibleSlides) {
      setDirection(-1);
    } else if (direction === -1 && current === 0) {
      setDirection(1);
    }
  };

  const settings = {
    infinite: true,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    speed: 2000,
    autoplaySpeed: 100,
    cssEase: "linear",
    afterChange: (current) => handleAfterChange(current),
    rtl: direction === -1,
  };

  const images = {
    php,
    android,
    flutter,
    ionic,
    reactNative,
    ios,
    aws,
    googleCloud,
    microsoftAzure,
    xamarin,
    odoo,
    sap,
    zoho,
    salesforce,
    powerBI,
    qlik,
    tableau,
    java: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/fullstack/javaTool.png`,
    python: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/fullstack/python.png`,

    javaScriptTool: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/fullstack/javaScriptTool.png`,
    dotNet: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/fullstack/dotNet.png`,
    mean: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/fullstack/mean.png`,
    mernTool: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/fullstack/mernTool.png`,
    mevnTool: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/fullstack/mevnTool.png`,

    angularJs: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/frontend/angularJs.png`,
    jquery: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/frontend/jquery.png`,
    nextjs: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/frontend/nextjs.png`,
    Reactjs: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/frontend/react.png`,
    vueJS: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/frontend/vueJS.png`,

    Chash: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/backend/c%23.png`,
    Cplus: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/backend/c%2B%2B.png`,
    nodeJS: `${EDIFY_S3_URL}/assets/technology/softwareDevelopment/backend/nodeJS.png`,
  };

  const [index, setIndex] = useState(0);
  const selectedChipData = _chipdata_[index];

  let interval;
  const [conditionStop, setconditionStop] = useState(true);
  useEffect(() => {
    if (conditionStop) {
      if (index < _chipdata_.length - 1) {
        interval = setInterval(() => {
          setIndex((prevIndex) => prevIndex + 1);
        }, 2000);
      } else {
        interval = setInterval(() => {
          setIndex(0);
        }, 2000);
      }
    }
    return () => clearInterval(interval);
  }, [index, setIndex]);

  const handleChipClick = (e) => {
    setIndex(e);
    clearInterval(interval);
    setconditionStop(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="d-flex justify-content-center">
        <ContentSection title={"Software Development"} textAlign={"start"} />
      </Grid>
      <Grid item xs={12} md={4} className="d-flex flex-column py-4">
        {_chipdata_.map((val, idx) => (
          <TechnologyChipComponent
            key={idx}
            label={val.iconTitle}
            index={idx}
            borderRadius={"2px"}
         
            sx={{ margin: "1%" }}
            onBtnClick={() => handleChipClick(idx)}
            selected={idx === index}
          />
        ))}
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={7.5}
        justifyContent={"flex-start"}
      
        className="mt-2 tech-content"
      >
        <Grid item xs={12}>
          <Typography className="fs-16 txt-description" text-start>
            {renderDangersoulyHTML(selectedChipData.description)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Slider ref={sliderRef} {...settings}>
            {selectedChipData.tools.map((value, ToolIdx) => (
              <Box key={ToolIdx} paddingLeft={"10px"} height={"80px"}>
                <img
                  src={images[value.software]}
                  alt="Software Development slider image"
                  className="w-100 h-100"
                />
              </Box>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TechnologySoftwareDevelopment;
