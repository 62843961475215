import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {COLORS} from "../../../constants/colorConstants"
const {BLUE_5,BLUE_2, ORANGE_2,BLUE_1}=COLORS;

function NavbarEvents({
  backgroundColor = BLUE_5,
  contentTextColor = BLUE_2,
  dateColor =  ORANGE_2,
}) {
  let eventData = [];

  const settings = {
    dots: eventData.length > 1,
    infinite: eventData.length > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: eventData.length > 1,
    autoplaySpeed: 4000,
    appendDots: (dots) => (
      <div className="navbar-event">
        <ul className="nav-event-dot"> {dots} </ul>
      </div>
    ),
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {eventData.length > 0 && (
        <Grid
          container
          bgcolor={backgroundColor}
          sx={{ paddingx: { sm: "4%", md: "8.3%", lg: "2%" } }}
          className=" align-items-center py-1"
        >
          <Grid item xs={12}>
            <Slider {...settings} className="slick-animate">
              {eventData.map((val, idx) => (
                <Grid
                  key={idx}
                  container
                  className="d-flex justify-content-between align-items-center px-1"
                >
                  {isSmallScreen ? (
                    <>
                      <Grid
                        container
                        item
                        xs={12}
                        className=" justify-content-center"
                      >
                        <Grid item xs={12} className="ps-2">
                          <Typography
                            className="fs-16 fw-700 text-center"
                            color={contentTextColor}
                          >
                            {val.content1}
                            <span style={{ color: `${dateColor}` }}>
                              {val.date}
                            </span>
                            <span> {val.mobileContent2}</span>
                          </Typography>
                        </Grid>
                        <Grid container item xs={6} className="ps-1 pt-1">
                          <Grid item xs={12}>
                            <Box>
                              <a
                                href={val.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Button
                                  className=" p-1 mb-1 w-100"
                                  variant="contained"
                                  sx={{
                                    borderRadius: "30px",
                                    paddingX: "5%",
                                    paddingY: "2%",
                                    backgroundColor: BLUE_1,
                                    "&:hover": {
                                      backgroundColor: BLUE_2,
                                    },
                                  }}
                                >
                                  Register Now
                                </Button>
                              </a>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        container
                        item
                        md={10.5}
                        xs={9.5}
                        className="align-items-center justify-content-center "
                      >
                        <Grid item md={12} sm={9} className="ps-2 ">
                          <Typography
                            className="fs-14 fw-700 text-center "
                            color={contentTextColor}
                          >
                            <span className="fs-18"> {val.content1}</span>

                            <span style={{ color: `${dateColor}` }}>
                              {val.date}
                            </span>
                            <span className="text-center"> {val.content2}</span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={2.5}
                        md={1.5}
                        zIndex={1000}
                        className="align-items-center justify-content-center"
                      >
                        <Grid item xs={12}>
                          <Box>
                            <a href={val.link} target="_blank" rel="noreferrer">
                              <Button
                                className="w-100"
                                variant="contained"
                                sx={{
                                  borderRadius: "10px",
                                  paddingX: "5%",
                                  paddingY: "1%",
                                  marginY: "0.1rem",
                                  backgroundColor: BLUE_1,
                                }}
                              >
                                Register Now
                              </Button>
                            </a>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              ))}
            </Slider>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default NavbarEvents;
