import axios from "axios";
const axiosInstance = axios.create({
  baseURL: "https://ixqkyqsi2z.ap-south-1.awsapprunner.com/",
  timeout: 1 * 60 * 1000,
  timeoutErrorMessage:
    "We are unable to connect to the server. Please try again later.",
});

axiosInstance.interceptors.request.use(async (config) => {
  document.getElementById("loader").classList.remove("d-none");
  document.getElementById("loader").classList.add("loadContainer");
  return config;
});

axiosInstance.interceptors.response.use(
  async (response) => {
    document.getElementById("loader").classList.remove("loadContainer");
    document.getElementById("loader").classList.add("d-none");
    return response;
  },
  async (error) => {
    document.getElementById("loader").classList.remove("loadContainer");
    document.getElementById("loader").classList.add("d-none");
    return Promise.reject(error);
  }
);
export { axiosInstance };
