import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Image } from "react-bootstrap";
import { CAREERS } from "../../../../../constants/joinOurTeamCard";

const { TITLE, SUBTITLE, IMAGE } = CAREERS;

function JoinOurTeamCard() {
  return (
    <Box className="darkBlueBackground careers-img" id="campus-programs">
      <Grid container spacing={2} className="padding-responsive">
        <Grid item xs={12} md={6} className="p-3">
          <Box>
            <Typography className="fw-700 career-page-title px-2 mt-4">
              {TITLE}
            </Typography>
            <Typography className="fs-16 fw-400  campus-prog-text px-2">
              {SUBTITLE}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={0} sm={12} md={6} className=" teamcard-img">
          <Box className="gap-3 p-5 m-1 centered-container">
            <Image src={IMAGE} height={"300px"} width={"90%"} alt="jodcard" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default JoinOurTeamCard;
