import { Card, CardMedia, Typography } from "@mui/material";
import React from "react";

function AboutCareerCard({
  OnCardClick = () => {},
  image = "",
  text = "Full Stack (MEAN)",
}) {
  return (
    <Card onClick={OnCardClick} className="about-carrear-card">
      <CardMedia
        component="img"
        image={image}
        alt="Campus Examination Room"
        className="about-card-media"
      />

      <Typography className="fs-16 fw-700 text-center mt-2">{text}</Typography>
    </Card>
  );
}

export default AboutCareerCard;
