import { Grid } from "@mui/material";
import React from "react";
import TechnologySoftwareDevelopment from "../../../../molecules/TechnologySoftwareDevelopment";

function SoftwareDevelopment() {
  return (
    <Grid container spacing={5}>
      <Grid item xs={2}></Grid>
      <Grid item xs={8}>
        <TechnologySoftwareDevelopment />
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
}

export default SoftwareDevelopment;
