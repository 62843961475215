import React from "react";

function ChatbotHeader({ onClose }) {
  return (
    <div className="chatbot-header">
      <span>
        Chat with Adya &nbsp;
        <span className="status">🟢Online</span>
      </span>
      <button onClick={onClose} className="close-button">
        X
      </button>
    </div>
  );
}

export default ChatbotHeader;
