export const LINKEDPAGES = [
  { label: "Home", route: "/" },
  {
    label: "Learning Programs",
    menuItems: [
      { label: "Campus Programs", route: "/campus-programs" },
      { label: "Off-Campus Programs", route: "/off-campus-programs" },
      { label: "Corporate Programs", route: "/corporate-programs" },
    ],
  },
  { label: "Technology", route: "/technology" },
  { label: "Careers", route: "/careers" },
  { label: "Contact Us", route: "/contact-us" },
];
