import React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const StarComponent = ({ value, review }) => {
  return (
    <>
      <Box
        component="fieldset"
        borderColor="transparent"
        className="d-flex align-items-center"
      >
        <Rating name="read-only" value={value} readOnly precision={0.1} />
        <Typography>{review}</Typography>
      </Box>
    </>
  );
};

export default StarComponent;
