import React, { useEffect, useRef, useState } from "react";
import CarouselNew from "../../molecules/carousel/CarouselNew";
import { Box, Grid, Paper, Typography } from "@mui/material";
import AccordionComponent from "../../atoms/AccordionComponent";
import Slider from "react-slick";
import CardComponent from "../../molecules/CardComponent";
import EventCardComponent from "../../molecules/EventCardComponent";
import WhatPeopleSaysCardComponent from "../../molecules/WhatPeopleSaysCardComponent/WhatPeopleSaysCardComponent";
import LatestNewsCardComponent from "../../molecules/LatestNewsCardComponent/LatestNewsCardComponent";
import BatchCardComponent from "../../molecules/BatchCardComponent";
import { getUpcomingBatchDates } from "../../../commonFunction/index";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CONTENT_DATA from "../../../constants/en.json";
import { getAllPrograms } from "../../../services/program/program";
import { useToasts } from "react-toast-notifications";
import { getAllBatches } from "../../../services/batches/batches";

const {
  _PEOPLE_SAYS_DATA_,
  _LATEST_NEWS_CARD_DATA_,
  _CORE_VALUES_DATA_,
  _STAFF_DATA_,
  _ACHIEVEMENTS_DATA_,
  _Vision_Description_,
  _Mission_Description_,
  _AboutUs_Left_,
  _Campus_Description_,
  _Off_Campus_Description_,
  _Corporate_Description_,
  _AboutUs_Left_End_,
  _AboutUs_Right_,
  _Engaging_Program_Description_,
  _Upto_Date_,
  _In_Depth_Description_,
  _Upcoming_Batch_Description_,
  _Achievement_Description_,
  _Popular_Program_,
  _Collaborations_With_Colleges_,
  _Aquire_,
  _Achivements_,
  _About_Us_,
  _Core_Values_,
  _Campus_,
  _Off_Campus_,
  _Corporate_,
  _Our_Upcoming_Batches_,
  _Our_Latest_News_,
  _What_People_Says_,
  _Engaging_Programs_,
  _Progress_Tracking_,
  _In_Depth_Assessments_,
  _Progress_Tracking_Description_,
  _Upto_Date_Description_,
  _Our_Popular_Programs_,
  _What_People_says_Description_,
  _Our_Latest_news_Description_,
} = CONTENT_DATA;

const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const whatPeopleSaysSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
let EDIFY_S3_URL = process.env.REACT_APP_DSEDIFY_S3_URL;

let image = EDIFY_S3_URL + "/assetsWebp/homepage/achivements.webp";
let aboutImg = EDIFY_S3_URL + "/assetsWebp/homepage/aboutUs.webp";
let coreImg = EDIFY_S3_URL + "/assetsWebp/homepage/corevalue.webp";

function HomePage() {
  const [expandedPanel, setExpandedPanel] = useState(false);
  const [selectedNews, setSelectedNews] = useState(_LATEST_NEWS_CARD_DATA_[0]);
  const sliderRef = useRef(null);
  const [direction, setDirection] = useState(1);
  const [rotateImage, setRotateImage] = useState(false);
  const { addToast } = useToasts();
  const [programDetails, setProgramDetails] = useState([]);
  const [onclickBatchEnquiry, setOnclickBatchEnquiry] = useState(false);
  const upcomingBatchDate = getUpcomingBatchDates();
  const [upcomingBatches, setUpcomingBatches] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    const handleDotFocus = (event) => {
      setTimeout(() => {
        event.target.blur();
      }, 100);
    };

    const handleDotBlur = (event) => {
    };

    const dots = document.querySelectorAll(".slick-dots li button");

    dots.forEach((dot, index) => {
      dot.dataset.index = index;
      dot.addEventListener("focus", handleDotFocus);
      dot.addEventListener("blur", handleDotBlur);
    });

    return () => {
      dots.forEach((dot) => {
        dot.removeEventListener("focus", handleDotFocus);
        dot.removeEventListener("blur", handleDotBlur);
      });
    };
  }, []);

  useEffect(() => {
    if (rotateImage) {
      const timer = setTimeout(() => {
        setRotateImage(false);
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [rotateImage]);

  const handleChange = (panel) => (event, isExpanded) => {
    setRotateImage(true);
    setExpandedPanel(isExpanded ? panel : false);
  };

  const handleAfterChange = (current) => {
    const totalSlides = sliderRef.current.props.children.length;
    const visibleSlides = settings.slidesToShow;

    if (direction === 1 && current >= totalSlides - visibleSlides) {
      setDirection(-1);
    } else if (direction === -1 && current === 0) {
      setDirection(1);
    }
  };

  const handleNewsClick = (data) => {
    setSelectedNews(data);
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };

  const latestCardSettings = {
    vertical: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    beforeChange: (current, next) =>
      setSelectedNews(_LATEST_NEWS_CARD_DATA_[next]),
    arrows: false,
  };

  const achievementsSettings = {
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    speed: 5000,
    autoplaySpeed: 100,
    cssEase: "linear",
    afterChange: (current) => handleAfterChange(current),
    rtl: direction === -1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getAllProgramsDetails();
    getAllBatchDetails();
  }, []);

  const getAllProgramsDetails = async () => {
    try {
      const { data, errRes } = await getAllPrograms();
      
      if (errRes) {
        addToast(errRes?.message, { appearance: "ERROR" });
        return;
      }
      if (data?.isError) {
        addToast(data?.message, { appearance: "ERROR" });
        return;
      }
      setProgramDetails(data?.data);
    } catch (error) {
      addToast({
        appearance: "ERROR",
      });
    }
  };

  const getAllBatchDetails = async () => {
    try {
      const { data, errRes } = await getAllBatches();
      if (errRes) {
        addToast(errRes?.message, { appearance: "ERROR" });
        return;
      }
      if (data?.isError) {
        addToast(data?.message, { appearance: "ERROR" });
        return;
      }
      const filteredBatches = data?.data?.filter(
        (batch) => batch?.status === "UPCOMING"
      );
      setUpcomingBatches(filteredBatches);
    } catch (error) {
      addToast({
        appearance: "ERROR",
      });
    }
  };

  const handleBtnClick = (selectedBatch) => {
    if (selectedBatch) {
      localStorage.setItem(
        "selectedBatchDetails",
        JSON.stringify(selectedBatch)
      );
      setOnclickBatchEnquiry(true);
      localStorage.setItem("onclickBatchEnquiry", true);
      navigate("/enquiry");
    }
  };

  return (
    <>
      <CarouselNew />
      <Grid
        container
        className="px-3 my-3 d-flex justify-content-center align-items-center text-center"
        spacing={2}
        py={2}
      >
        <Grid item xs={12} md={6}>
          <img
            className={`mt-5 
               ${
                 expandedPanel === "values" ? "core-image" : "about-Img"
               } home-vission
              ${rotateImage ? "rotate-360" : ""} `}
            src={expandedPanel === "values" ? coreImg : aboutImg}
            alt="../"
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center text-start"
        >
          <AccordionComponent
            AccordionTitle={"Our Vision"}
            isExpand={expandedPanel === "vision"}
            handleChangeExpand={handleChange("vision")}
          >
            <Typography className="p-2">{_Vision_Description_}</Typography>
          </AccordionComponent>
          <AccordionComponent
            AccordionTitle={"Our Mission"}
            isExpand={expandedPanel === "mission"}
            handleChangeExpand={handleChange("mission")}
          >
            <Typography className="p-2">{_Mission_Description_}</Typography>
          </AccordionComponent>
          <AccordionComponent
            AccordionTitle={
              <>
                <span className="fs-22 fw-500">
                  {_Core_Values_} -{" "}
                  <span className="fs-22 fw-600">{_Aquire_}</span>
                </span>
              </>
            }
            isExpand={expandedPanel === "values"}
            handleChangeExpand={handleChange("values")}
          >
            <Typography>
              <div className="d-grid align-items-center justify-content-start">
                {_CORE_VALUES_DATA_.map((val) => (
                  <Box key={val} className="px-3 py-1">
                    <Typography className="fs-18 fw-700">
                      {val._title_}
                    </Typography>
                    <Typography className="fs-14 fw-300">
                      {val._description_}
                    </Typography>
                  </Box>
                ))}
              </div>
            </Typography>
          </AccordionComponent>
        </Grid>

        <Grid item xs={12} className="text-center mt-4">
          <Typography className="fs-28 fw-600">{_About_Us_}</Typography>
        </Grid>

        <Grid item xs={12} className="mb-2">
          <Paper elevation={0} className="p-3">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper className="px-4 py-3 about-us" elevation={3}>
                  <Typography className="fs-16 fw-600 text-start prog-desc">
                    {_AboutUs_Left_}
                  </Typography>
                  <ul className="text-start mt-2" about-desc>
                    <li className="fs-18 fw-600">{_Campus_}</li>
                    <p>{_Campus_Description_}</p>
                    <li className="fs-18 fw-600">{_Off_Campus_}</li>
                    <p>{_Off_Campus_Description_}</p>
                    <li className="fs-18 fw-600">{_Corporate_}</li>
                    <p>{_Corporate_Description_}</p>
                  </ul>
                  <Typography className="fs-16 fw-300 text-start">
                    {_AboutUs_Left_End_}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className="px-4 py-3 about-us" elevation={3}>
                  <Typography className="fs-16 fw-600 text-start prog-desc">
                    {_AboutUs_Right_}
                  </Typography>
                  <ul className="text-start mt-2" about-desc>
                    <li className="fs-18 fw-600">{_Engaging_Programs_}</li>
                    <p>{_Engaging_Program_Description_}</p>
                    <li className="fs-18 fw-600">{_Progress_Tracking_}</li>
                    <p>{_Progress_Tracking_Description_}</p>
                    <li className="fs-18 fw-600">{_Upto_Date_}</li>
                    <p>{_Upto_Date_Description_}</p>
                    <li className="fs-18 fw-600">{_In_Depth_Assessments_}</li>
                    <p>{_In_Depth_Description_}</p>
                  </ul>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        className="d-flex justify-content-center align-items-center text-center"
        sx={{
          mt: upcomingBatches.length > 0 ? 5 : 2,
        }}
        pb={5}
      >
        {upcomingBatches.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography className="fs-28 fw-600">
                {_Our_Upcoming_Batches_}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="fs-18">
                {_Upcoming_Batch_Description_}
              </Typography>
            </Grid>
          </>
        )}

        {upcomingBatches.map((batchData, index) => {
          const {
            courseLogo,
            programName,
            batchName,
            batchDescription,
            batchStartDate,
            batchEndDate,
            batchStartTime,
            batchEndTime,
            batchId,
            batchMode,
          } = batchData;

          return (
            <Grid item xs={12} sm={4} md={3} key={index} className="p-2">
              <BatchCardComponent
                image={courseLogo}
                title={`${programName} - ${batchName}`}
                description={batchDescription}
                batchStartDate={batchStartDate}
                batchEndDate={batchEndDate}
                batchStartTime={batchStartTime}
                batchEndTime={batchEndTime}
                batchId={batchId}
                batchMode={batchMode}
                altText={batchName}
                onBtnClick={() => handleBtnClick(batchData)}
              />
            </Grid>
          );
        })}
      </Grid>

      <Grid container className="text-start darkBlueBackground text-light">
        <Grid item xs={12}>
          <Typography className="fs-28 fw-600 text-center py-3">
            {_Achivements_}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="d-grid justify-content-start align-items-center"
          px={7}
        >
          <Box>
            <Typography className="fs-18 py-3">
              {_Achievement_Description_}
            </Typography>
          </Box>
          <Box className="d-flex justify-content-center ">
            <img
              loading="lazy"
              src={image}
              className="card-image achive-cont"
              alt="achivement image"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className="row justify-content-center mt-4">
          {_STAFF_DATA_.map((data, index) => (
            <Box
              className="col-md-6 m-3 p-2 pt-3  border border-1 border-secondary rounded-0 achive-card"
              key={index}
            >
              <Typography className="text-center achive-crds">
                {data._numbers_}
              </Typography>
              <Typography className="text-center achive-crds">
                {data._name_}
              </Typography>
            </Box>
          ))}
        </Grid>

        <Grid item xs={12} pb={5}>
          <Typography className="fs-28 fw-600 text-center py-3">
            {_Collaborations_With_Colleges_}
          </Typography>
          <Slider {...achievementsSettings} pauseOnHover={false}>
            {_ACHIEVEMENTS_DATA_.map((value, ToolIdx) => (
              <Box key={ToolIdx} className="bg-white py-3">
                <Link
                  to={value._link_}
                  target="_blank"
                  className="text-decoration-none"
                >
                  <Box
                    className="activement-section"
                    margin="0 10px"
                    height={"20vh"}
                  >
                    <img
                      src={`${EDIFY_S3_URL}/${value._image_}`}
                      alt={value._clgFullName_}
                      height={"90%"}
                      width={"130%"}
                      className="px-5"
                    />
                    <Typography className="fs-20 fw-800 collabration-clg">
                      {value._clgFullName_}
                    </Typography>
                  </Box>
                </Link>
              </Box>
            ))}
          </Slider>
        </Grid>
      </Grid>

      <Grid
        container
        className="my-4 py-3 d-flex justify-content-center align-items-center text-center our-popular-prog"
      >
        <Grid item xs={12}>
          <Typography className="fs-28 fw-600">
            {_Our_Popular_Programs_}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="fs-18">{_Popular_Program_}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            className="py-3 justify-content-center px-2"
            spacing={2}
          >
            {programDetails?.map((course, index) => (
              <Grid item xs={12} sm={5} lg={3} key={index}>
                <CardComponent
                  image={course.programLogo}
                  title={course.programName}
                  courseFee={course.courseFee.toFixed(2)}
                  description={course.programDesc}
                  enrollBtnClk={() =>
                    window.open(
                      `${process.env.REACT_APP_DSEDIFY_UI_URL}/programs/enroll/${course?.programId}`
                    )
                  }
                  programId={course.programId}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        className="py-5 d-flex justify-content-center align-items-center text-center darkBlueBackground text-light"
      >
        <Grid item xs={12}>
          <Typography className="fs-28 fw-600">{_What_People_Says_}</Typography>
        </Grid>
        <Grid item xs={12} px={2}>
          {" "}
          <Typography className="fs-18">
            {_What_People_says_Description_}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className="slider-container">
            <Slider {...whatPeopleSaysSettings} className="card-slick">
              {_PEOPLE_SAYS_DATA_.map((data, index) => (
                <Box key={index}>
                  <WhatPeopleSaysCardComponent
                    description={data._description_}
                    personName={data?._personName_}
                    iconImage={`${EDIFY_S3_URL}/${data?._iconImage_}`}
                  />
                </Box>
              ))}
            </Slider>
          </div>
        </Grid>
      </Grid>

      <Grid container className="my-4  d-flex px-5 our-latest-news" spacing={2}>
        <Grid item xs={12}>
          <Typography className="fs-28 fw-600 text-center">
            {_Our_Latest_News_}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="fs-18 text-center">
            {_Our_Latest_news_Description_}
          </Typography>
        </Grid>
        <Grid xs={12} md={6} className="mt-4 px-3">
          <EventCardComponent
            cardWidth="100%"
            cardHeight="100%"
            image={`${EDIFY_S3_URL}/${selectedNews._image_}`}
            title={selectedNews._title_}
            date={selectedNews._date_}
            readMoreButton="true"
            description="true"
            descriptionText={selectedNews._subtitle_}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className="p-2 mt-1">
            <Box className="our-latest-card">
              <Slider {...latestCardSettings} ref={sliderRef}>
                {_LATEST_NEWS_CARD_DATA_.map((data, index) => (
                  <Box key={index}>
                    <Grid item xs={12} className="p-2">
                      <LatestNewsCardComponent
                        cardHeight="170px"
                        cardWidth={"100%"}
                        title={data._title_}
                        date={data._date_}
                        subtitle={data._subtitle_}
                        onCardClk={() => handleNewsClick(data)}
                        selectedNews={selectedNews}
                        cardBorder={selectedNews === data}
                        image={`${EDIFY_S3_URL}/${data._image_}`}
                      />
                    </Grid>
                  </Box>
                ))}
              </Slider>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default HomePage;
