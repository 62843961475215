import elipTabsData from "../ProgramsTabsData/elipTabsData";
import LEARNING_CONTENT_DATA from "../en.json";

const {
  OFFCAMPUS_PROGRAMS_DATA,
  Pdp_What_You_Will_Learn,
  Pdp_How_It_Works,
  Eligibility_Criteria,
  How_It_Works,
  What_You_will_learn,
  Pdp_Eligibility_Criteria_Description,
} = LEARNING_CONTENT_DATA;

const pdpTabsData = [
  {
    iconContent: Eligibility_Criteria,
    description: Pdp_Eligibility_Criteria_Description,
  },
  {
    iconContent: How_It_Works,
    details: Pdp_How_It_Works,
  },
  {
    iconContent: What_You_will_learn,
    details: Pdp_What_You_Will_Learn,
  },
];

const offCampusTabsData = {
  pdpTabsData,
  elipTabsData,
};

const OFFCAMPUS_PROGRAMS_CONTENT = OFFCAMPUS_PROGRAMS_DATA.map((program) => {
  return {
    id: program._id_,
    title: program._title_,
    description: program._description_,
    image: program._image_,
    buttonLabel: program._buttonLabel_,
    tabsData: offCampusTabsData[program._tabsData_],
  };
});

export default OFFCAMPUS_PROGRAMS_CONTENT;
