import axios from "axios";
import DUMMY_CONTENT from "../../constants/dummyJSON/dummy.json";
const {_UPCOMING_BATCH_DATA_DUMMY_}=DUMMY_CONTENT
const isFeatureEnabled = process.env.REACT_APP_FEATURE_FLAG === 'true';

const getAllBatches = async () => {
  try {
    if(isFeatureEnabled){
      const res = await axios.get(
        `${process.env.REACT_APP_DSEDIFY_BACKEND_URL}/api/v2/batch/all`
      );
      return { data: res?.data };
    }else{
      const res =_UPCOMING_BATCH_DATA_DUMMY_
      return { data: res?.data };
    }
    
  } catch (err) {
    return { errRes: err?.response?.data || err };
  }
};
export { getAllBatches };
