import CONTENT_DATA from "../constants/en.json";

const {
  _CampusProgram_,
  _OffCampusProgram_,
  _CarporateProgram_,
  _Others_,
  _CampusInternshipProgram_,
  _CampusProjectProgram_,
  _TechConnectProgram_,
  _StudentSuccessProgram_,
  _FacultyDevelopmentProgram_,
  _FacultyMirroringProgram_,
  _ExperienceLearningInternshipProgram_,
  _ProfessionalDevelopmentProgram_,
  _ExecutiveLearningProgram_,
  _ImmersiveLearningProgram_,
  _VirtualLearningProgram_,
  _BE_,
  _BTech_,
  _BCA_,
  _BSCInComputerScience_,
  _MCA_,
  _MTechME_,
  _MSCInComputerScience_,
  _ISemester_,
  _IISemester_,
  _IIISemester_,
  _IVSemester_,
  _VSemester_,
  _VISemester_,
  _VIISemester_,
  _VIIISemester_,
  _ReactNative_,
  _FrontendReact_,
  _BackendJava_,
  _JavaFullstackwithReact_,
  _MERNStack_,
  _WebAutomationTestingWithSelenium_,
  _MobileAutomationTestingWithAppium_,
  _EmbeddedSystems_,
} = CONTENT_DATA;

export const INTRESTED_OPTION = [
  {
    id: "Campus Program",
    label: _CampusProgram_,
  },
  {
    id: "Off-Campus Program",
    label: _OffCampusProgram_,
  },
  {
    id: "Corporate Program",
    label: _CarporateProgram_,
  },
  {
    id: "Others",
    label: _Others_,
  },
];

export const PROGRAMS = [
  {
    program: "Campus Program",
    id: "Campus Internship Program",
    label: _CampusInternshipProgram_,
  },
  {
    program: "Campus Program",
    id: "Campus Project Program",
    label: _CampusProjectProgram_,
  },
  {
    program: "Campus Program",
    id: "Tech Connect Program",
    label: _TechConnectProgram_,
  },
  {
    program: "Campus Program",
    id: "Student Success Program",
    label: _StudentSuccessProgram_,
  },
  {
    program: "Campus Program",
    id: "Faculty Development Program",
    label: _FacultyDevelopmentProgram_,
  },
  {
    program: "Campus Program",
    id: "Faculty Mirroring Program",
    label: _FacultyMirroringProgram_,
  },
  {
    program: "Off-Campus Program",
    id: "Experience Learning Internship Program(ELIP)",
    label: _ExperienceLearningInternshipProgram_,
  },
  {
    program: "Off-Campus Program",
    id: "Professional Development Program(PDP)",
    label: _ProfessionalDevelopmentProgram_,
  },
  {
    program: "Corporate Program",
    id: "Executive Learning Program",
    label: _ExecutiveLearningProgram_,
  },
  {
    program: "Corporate Program",
    id: "Immersive Learning Program",
    label: _ImmersiveLearningProgram_,
  },
  {
    program: "Corporate Program",
    id: "Virtual Learning Program",
    label: _VirtualLearningProgram_,
  },

  {
    id: "Others",
  },
];

export const DEGREEOPTION = [
  { id: "BE", label: _BE_ },
  { id: "B Tech", label: _BTech_ },
  { id: "BCA", label: _BCA_ },
  { id: "BSC in Computer Science", label: _BSCInComputerScience_ },
  { id: "MCA", label: _MCA_ },
  { id: "MTech/ME", label: _MTechME_ },
  { id: "MSC in Computer Science", label: _MSCInComputerScience_ },
  { id: "Others", label: _Others_ },
];

export const SEMESTEROPTIONS = [
  { id: "1 Semester", label: _ISemester_ },
  { id: "II Semester", label: _IISemester_ },
  { id: "III Semester", label: _IIISemester_ },
  { id: "IV Semester", label: _IVSemester_ },
  { id: "V Semester", label: _VSemester_ },
  { id: "VI Semester", label: _VISemester_ },
  { id: "VII Semester", label: _VIISemester_ },
  { id: "VIII Semester", label: _VIIISemester_ },
];

export const INTRESTEDOPTIONS = [
  {
    id: "Campus Internship Program(CIP)",
    label: _CampusInternshipProgram_,
  },
  {
    id: "Campus Project Program(CPP)",
    label: _CampusProjectProgram_,
  },
  {
    id: "Experience Learning Internship Program(ELIP)",
    label: _ExperienceLearningInternshipProgram_,
  },
  {
    id: "Professional Development Program(PDP)",
    label: _ProfessionalDevelopmentProgram_,
  },
];

export const TECHNOLOGYOPTIONS = [
  {
    id: "React Native",
    label: _ReactNative_,
  },
  {
    id: "Frontend React",
    label: _FrontendReact_,
  },
  {
    id: "Backend Java",
    label: _BackendJava_,
  },
  {
    id: "Java Full stack with React",
    label: _JavaFullstackwithReact_,
  },
  {
    id: "MERN Stack",
    label: _MERNStack_,
  },
  {
    id: "Web Automation Testing With Selenium",
    label: _WebAutomationTestingWithSelenium_,
  },
  {
    id: "Mobile Automation Testing With Appium",
    label: _MobileAutomationTestingWithAppium_,
  },
  {
    id: "Embedded Systems",
    label: _EmbeddedSystems_,
  },
];
