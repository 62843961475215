const S3_URL = process.env.REACT_APP_DSEDIFY_S3_URL;
const AutomationTools = `${S3_URL}/assets/technology/AutomationTools.png`;
const performanceTestingTool = `${S3_URL}/assets/technology/performanceTestingTool.png`;
const securityTestingTools = `${S3_URL}/assets/technology/securityTestingTools.png`;
const apiTestingTools = `${S3_URL}/assets/technology/apiTestingTools.png`;
const etlTestingTools = `${S3_URL}/assets/technology/etlTestingTools.png`;

export const TABS_DATA = [
  {
    iconContent: "Automation Testing",
    description: `Our excellency in Automation Testing, utilizing industry-leading tools such as Selenium, Appium, Katalon Studio, Ranorax, and adopting methodologies like TDD, BDD, Cucumber, and RFT. Trust us for unmatched competency in fulfilling diverse technology requirements.`,
    toolsImage: AutomationTools,
  },
  {
    iconContent: "Performance Testing",
    description: `We showcase unparalleled expertise in performance testing through a spectrum of industry-leading tools including Apache JMeter, IBM RPT, Neo Load, Blaze Meter, and LoadRunner. Our seasoned professionals are adept at tailoring comprehensive solutions, ensuring optimal performance, scalability, and reliability for your applications. With a proven track record of managing diverse requirements in performance testing, Dyashin stands as a reliable partner capable of meeting and exceeding the expectations of clients across various domains. Trust us to elevate the performance of your software and applications through rigorous testing and unmatched technological proficiency.`,
    toolsImage: performanceTestingTool,
  },
  {
    iconContent: "Security Testing",
    description: `We possess unparalleled expertise in security testing, employing cutting-edge tools such as SonarQube, BurpSuite, AppScanner, SQLMap, and Acunetix. Our proficient team is adept at ensuring the utmost security for your applications and systems. With a proven track record, we confidently handle diverse requirements, offering comprehensive security testing solutions. Whether it's vulnerability assessments, penetration testing, or code analysis, our commitment to excellence guarantees robust security measures. Partner with Dyashin Technosoft for state-of-the-art security testing services, safeguarding your digital assets and ensuring a resilient defence against potential threats in the ever-evolving landscape of cybersecurity.`,
    toolsImage: securityTestingTools,
  },
  {
    iconContent: "API Testing",
    description: `We showcase our prowess in API testing through the adept use of industry-leading tools such as Postman, Rest Assured, and SOAP UI. With comprehensive expertise and a proven track record, we confidently address diverse client requirements in API testing. Our skilled team ensures seamless integration, robust performance, and meticulous validation of APIs, demonstrating our commitment to delivering high-quality solutions. Whether it's testing RESTful or SOAP APIs, Dyashin stands as a reliable partner, guaranteeing precision and efficiency in every testing endeavour. Trust us for unparalleled proficiency in API testing, meeting the demands of the dynamic digital landscape.`,
    toolsImage: apiTestingTools,
  },
  {
    iconContent: "ETL/DWH Testing",
    description: `We excel in utilizing SolidWorks, demonstrating our capability and competency to meet diverse design requirements. Trust us to deliver innovative solutions with precision and efficiency in every project.`,
    toolsImage: etlTestingTools,
  },
];
