import React, { useEffect } from "react";
import SoftwareDevelopment from "./SoftwareDevelopment";
import EngineeringServices from "./EngineeringServices";
import SoftwareTesting from "./SoftwareTesting";
import ESystem from "./ESystem";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import AboutCareerCard from "../../../molecules/cards/AboutCareerCard";
import CONTENT_DATA from "../../../../constants/en.json";
const { _Technology_Description_ } = CONTENT_DATA;
let EDIFY_S3_URL = process.env.REACT_APP_DSEDIFY_S3_URL;
let embedded = EDIFY_S3_URL+"/assetsWebp/technology/embedded.webp";
let testing = EDIFY_S3_URL+"/assetsWebp/technology/testing.webp";
let enggService = EDIFY_S3_URL+"/assetsWebp/technology/enggServices.webp";
let devlopment = EDIFY_S3_URL+"/assetsWebp/technology/development.webp";
function TechnologyPage() {
  const { section } = useParams();

  useEffect(() => {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        const yOffset = -60;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [section]);
  return (
    <>
      <Box className="tech-container">
        <Box className="py-5 tech-bcol" id="campus-programs">
          <Grid container rowGap={2} rowSpacing={2} spacing={3}>
            <Grid item xs={0} md={1.5}></Grid>
            <Grid item xs={12} md={3.5}>
              <Box className="">
                <Typography className="  fw-700 technology-page-title " px={2}>
                  Technology
                </Typography>
                <Typography
                  className="fs-16 fw-400 mt-2 campus-prog-text"
                  px={2}
                >
                  {_Technology_Description_}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={6}
              className=" tech-card gap-3 py-4"
            >
              <Grid
                item
                className="d-flex justify-content-center align-items-center p-2 gap-3"
              >
                <AboutCareerCard
                  text="Software Development"
                  image={devlopment}
                ></AboutCareerCard>

                <AboutCareerCard
                  text="Software QA & Testing"
                  image={testing}
                ></AboutCareerCard>
              </Grid>

              <Grid
                item
                className="d-flex justify-content-center align-items-center p-2 gap-3"
              >
                <AboutCareerCard
                  text="Engineering Services"
                  image={enggService}
                ></AboutCareerCard>

                <AboutCareerCard
                  text="Embedded Systems"
                  image={embedded}
                ></AboutCareerCard>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={5} className="mt-4">
          <Grid item xs={12} className="py-4" id="software-development">
            <SoftwareDevelopment />
          </Grid>
          <hr className="custom-hr" />
          <Grid item xs={12} className="py-4" id="software-testing">
            <SoftwareTesting />
          </Grid>
          <hr className="custom-hr" />

          <Grid item xs={12} className="py-4 " id="engineering-services">
            <EngineeringServices />
          </Grid>
          <hr className="custom-hr" />

          <Grid item xs={12} className="py-4" id="embedded-system">
            <ESystem />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default TechnologyPage;
