import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Button from "@mui/material/Button";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CONTENT_DATA from "../../../constants/en.json";

const { _ReadMore_, _ReadLess_ } = CONTENT_DATA;

const EventCardComponent = ({
  cardWidth = 300,
  cardHeight = 300,
  image = {},
  date = "",
  title = "",
  time = "",
  venue = "",
  joinEventButton = false,
  readMoreButton = false,
  timeAndVenueLogo = false,
  description = false,
  descriptionText = "descriptionText",
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card className="event-card-main">
      <CardMedia
        component="img"
        image={image}
        alt="Campus Examination Room"
        className="event-card-media"
      />

      <CardContent>
        <Grid
          container
          className="d-flex justify-content-center align-items-start text-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <Box className="d-flex">
              <CalendarMonthIcon className="calender-month-icon" />
              &nbsp;
              <Typography>{date}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} className="event-scrlbar">
            <Typography className="fs-20 fw-600">{title}</Typography>
            {description && (
              <Grid item xs={12} className="mt-2">
                <Typography className="event-description">
                  {isExpanded
                    ? descriptionText
                    : `${descriptionText.substring(0, 200)}...`}
                </Typography>
              </Grid>
            )}
            {isExpanded && (
              <Grid item xs={12} className="mt-3 d-flex justify-content-start">
                <Button
                  className="event-card-btn"
                  onClick={() => toggleReadMore()}
                >
                  {_ReadLess_}
                </Button>
              </Grid>
            )}
          </Grid>
          {timeAndVenueLogo && (
            <Grid item xs={12}>
              <Box className="d-flex gap-3">
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                >
                  <ScheduleIcon className="schedule-icon" />
                  {time}
                </Typography>
                |
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                >
                  <LocationOnIcon className="location-icon" />
                  {venue}
                </Typography>
              </Box>
            </Grid>
          )}
          {joinEventButton && (
            <Grid item xs={12}>
              <Typography  className=" join-event ">
                Join Event
                <ChevronRightIcon />
              </Typography>
            </Grid>
          )}
        </Grid>
        {readMoreButton && !isExpanded && (
          <Grid item xs={12} className="mt-3 d-flex justify-content-start">
            <Button className="event-card-btn" onClick={() => toggleReadMore()}>
              {_ReadMore_}{" "}
            </Button>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default EventCardComponent;
