import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import {COLORS} from "../../../constants/colorConstants"
const {WHITE_4}=COLORS;

const navTheme = createTheme({
  palette: {
    primary: {
      main: WHITE_4,
    },
    secondary: {
      main: WHITE_4,
    },
    error: {
      main: red.A400,
    },
  },
});

export default navTheme;
