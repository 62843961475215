import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
function LatestNewsCardComponent({
  cardHeight = "",
  cardWidth = "",
  title = "",
  date = "",
  subtitle = "",
  onCardClk = () => {},
  image = {},
  cardBorder,
}) {
  return (
    <>
      <Card
        sx={{
          height: cardHeight,
          width: cardWidth,
        }}
        elevation={3}
      >
        <CardActionArea
          className={`actionarea ${cardBorder ? "with-border" : ""}`}
          onClick={onCardClk}
        >
          <Grid container className="gap-4 px-3">
            <Grid item xs={3}>
              <CardMedia
                className="latest-mediacard"
                image={image}
                title="Card title"
              />
            </Grid>
            <Grid item xs={8}>
              <Box className="d-grid text-start gap-2">
                <Typography className="fs-20 fw-600">{title}</Typography>
                <Box className="d-flex">
                  <CalendarMonthIcon className="calender-month-icon" />
                  &nbsp;
                  <Typography>{date}</Typography>
                </Box>
                <Typography className="text-truncate">{subtitle}</Typography>
              </Box>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </>
  );
}

export default LatestNewsCardComponent;
