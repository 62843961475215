import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import InputBoxComponent from "../../atoms/InputBoxComponent";
import ButtonComponent from "../../atoms/ButtonComponent/ButtonComponent";
import { useToasts } from "react-toast-notifications";
import validateMessage from "../../../constants/validateMessages";
import validationRegex from "../../../services/utils/regexUtils";
import MultiSelectDropDown from "../../atoms/MultiSelectDropDown";
import TextAreaComponent from "../../atoms/TextAreaComponent";
import ContactUsMap from "../../molecules/ContactUsMap";
import { contactUs } from "../../../services/contact/contactUs";

import { INTRESTED_OPTION, PROGRAMS } from "../../../constants/globalConstant";
import CONTENT_DATA from "../../../constants/en.json";
import {COLORS} from "../../../constants/colorConstants"
const {RED_1,WHITE_3,BLUE_1,PRIMARY}=COLORS;
const { _ConnectWith_Description_ } = CONTENT_DATA;

function ConnectWithUs() {
  const { addToast } = useToasts();
  const [formData, setformData] = useState({
    fullName: "",
    emailId: "",
    contactNo: "",
    organizationName: "",
    interestedIn: [],
    PROGRAMS: [],
    description: "",
  });

  const [fullNameErrorMsg, setFullNameErrorMsg] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [numberErrorMsg, setNumberErrorMsg] = useState("");
  const [organisationErrorMsg, setOrganisationErrorMsg] = useState("");
  const [programOptions, setProgramOptions] = useState([]);
  const [intrests, setIntrests] = useState([]);

  const handleChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleMultiSelectChange = (field, selected, type) => {
    const selectedIds = selected.map((item) => item.id);
    setformData({ ...formData, [field]: selectedIds });
    if (field === "interestedIn") {
      setIntrests(selectedIds);
    } else {
      setProgramOptions(selectedIds);
    }
  };

  const validateFullName = () => {
    let isFullNameValid = true;
    if (formData?.fullName === "") {
      isFullNameValid = false;
      setFullNameErrorMsg(validateMessage?.field_required);
    } else if (formData?.fullName.length > 30) {
      isFullNameValid = false;
      setFullNameErrorMsg(`${validateMessage?.maxLength} 30 characters`);
    } else if (!validationRegex?.name.test(formData?.fullName)) {
      isFullNameValid = false;
      setFullNameErrorMsg(validateMessage?.alphabets);
    } else {
      isFullNameValid = true;
      setFullNameErrorMsg("");
    }
    return isFullNameValid;
  };

  const validateEmail = () => {
    let isEmailValid = true;
    if (formData?.emailId === "") {
      isEmailValid = false;
      setEmailErrorMsg(validateMessage.field_required);
    } else if (formData?.emailId.length > 30) {
      isEmailValid = false;
      setEmailErrorMsg(`${validateMessage?.maxLength} 30 characters`);
    } else if (!validationRegex.email.test(formData?.emailId)) {
      isEmailValid = false;
      setEmailErrorMsg(validateMessage.email);
    } else {
      isEmailValid = true;
      setEmailErrorMsg("");
    }
    return isEmailValid;
  };

  const validateContactNumber = () => {
    let ismNumberValid = true;
    if (formData?.contactNo === "") {
      ismNumberValid = false;
      setNumberErrorMsg(validateMessage.field_required);
    } else if (!validationRegex?.mobile.test(formData?.contactNo)) {
      ismNumberValid = false;
      setNumberErrorMsg(validateMessage.mobile);
    } else {
      ismNumberValid = true;
      setNumberErrorMsg("");
    }
    return ismNumberValid;
  };

  const validateOrganisationName = () => {
    let isOrganisationNameValid = true;
    if (
      formData?.organizationName !== "" &&
      formData?.organizationName.length > 150
    ) {
      isOrganisationNameValid = false;
      setOrganisationErrorMsg(`${validateMessage?.maxLength} 150 characters`);
    } else {
      isOrganisationNameValid = true;
      setOrganisationErrorMsg("");
    }
    return isOrganisationNameValid;
  };

  const submitFormData = async () => {
    const { data, errRes } = await contactUs(formData);

    if (data) {
      if (data.error) {
        addToast(data.message, { apperance: "error" });
      } else {
        addToast(data.message, { apperance: "success" });
        handleClearForm();
      }
    } else if (errRes) {
    }
  };

  const handleClearForm = () => {
    setformData({
      fullName: "",
      emailId: "",
      contactNo: "",
      organizationName: "",
      interestedIn: [],
      PROGRAMS: [],
      description: "",
    });
    setIntrests([]);
    setProgramOptions([]);
    setFullNameErrorMsg("");
    setEmailErrorMsg("");
    setNumberErrorMsg("");
    setOrganisationErrorMsg("");
  };

  const isOthersSelected = formData?.interestedIn.includes("Others");
  const shouldShowProgramInput = formData?.interestedIn.some(
    (option) => option !== "Others",
  );
  const shouldShowTextArea = isOthersSelected;

  const validateData = () => {
    let isNameValid = validateFullName();
    let isEmailValid = validateEmail();
    let isContactNumberValid = validateContactNumber();
    let isOrganisationNameValid = validateOrganisationName();

    if (
      isNameValid &&
      isEmailValid &&
      isContactNumberValid &&
      isOrganisationNameValid
    ) {
      submitFormData();
    }
  };

  return (
    <>
      <Box className="darkBlueBackground" id="campus-PROGRAMS">
        <Grid container rowSpacing={2}>
          <Grid item md={1}></Grid>
          <Grid item xs={12} md={5}>
            <Box className="mt-5 pb-4">
              <Typography className="fw-700 page-title px-3 " lineHeight={1}>
                Connect <br />
                With Us
              </Typography>
              <Typography className="fs-16 fw-400 mt-4 campus-prog-text px-3">
                {_ConnectWith_Description_}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={0} sm={12} md={4.5} className="px-5 map">
            <Box>
              <ContactUsMap />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="mt-4 mb-5" paddingBottom={20}>
        <Grid container gap={2} className="justify-content-center">
          <Grid item xs={12} sm={9}>
            <Paper elevation={3} className="px-4 position-relative">
              <Grid container spacing={3} className="p-2 mt-2 ">
                <Grid item xs={12} md={6}>
                  <InputBoxComponent
                    textLabel="Full Name"
                    placeholder="Enter your full name"
                    name="fullName"
                    value={formData?.fullName}
                    onChange={handleChange}
                    onBlur={validateFullName}
                    errorText={fullNameErrorMsg}
                    required="true"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputBoxComponent
                    textLabel="Email ID"
                    placeholder="Enter your email address"
                    name="emailId"
                    value={formData?.emailId}
                    onChange={handleChange}
                    onBlur={validateEmail}
                    errorText={emailErrorMsg}
                    required="true"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputBoxComponent
                    textLabel="Contact Number"
                    placeholder="Enter your contact number"
                    name="contactNo"
                    value={formData?.contactNo}
                    onChange={handleChange}
                    onBlur={validateContactNumber}
                    errorText={numberErrorMsg}
                    required="true"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputBoxComponent
                    textLabel="Organization Name"
                    placeholder="Enter your organization name"
                    name="organizationName"
                    value={formData?.organizationName}
                    onBlur={validateOrganisationName}
                    onChange={handleChange}
                    errorText={organisationErrorMsg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MultiSelectDropDown
                    textLabel="Interested In"
                    name="interestedIn"
                    value={INTRESTED_OPTION.filter((item) =>
                      intrests.includes(item.id),
                    )}
                    options={INTRESTED_OPTION}
                    onChange={(e) =>
                      handleMultiSelectChange("interestedIn", e, "multi")
                    }
                  />
                </Grid>
                {shouldShowProgramInput && (
                  <Grid item xs={12} md={6}>
                    <MultiSelectDropDown
                      textLabel="PROGRAMS"
                      name="PROGRAMS"
                      options={PROGRAMS.filter((item) =>
                        formData?.interestedIn.includes(item.program),
                      )}
                      value={PROGRAMS.filter((item) =>
                        formData?.PROGRAMS.includes(item.id),
                      )}
                      onChange={(e) =>
                        handleMultiSelectChange("PROGRAMS", e, "multi")
                      }
                    />
                  </Grid>
                )}
                {shouldShowTextArea && (
                  <Grid item xs={12}>
                    <TextAreaComponent
                      textLabel="Description"
                      name="description"
                      value={formData?.description}
                      onChange={handleChange}
                      placeholder="Describe more about your interest"
                    />
                  </Grid>
                )}
                <Grid item xs={12} className="d-flex justify-content-end">
                  <ButtonComponent
                    label="Clear All"
                    className="fs-12 clear-all-btn"
                    onBtnClick={() => {
                      handleClearForm();
                    }}
                    sx={{
                      marginRight: "10px",
                      backgroundColor: RED_1,
                      borderRadius: "20px",
                      color:WHITE_3,
                      "&:hover": {
                        backgroundColor: RED_1,
                      },
                    }}
                  />

                  <ButtonComponent
                    label="Submit"
                    className="fs-12 submit-btn"
                    sx={{
                      backgroundColor: PRIMARY,
                      borderRadius: "20px",
                      color: WHITE_3,

                      "&:hover": {
                        backgroundColor: BLUE_1,
                      },
                    }}
                    onBtnClick={() => {
                      validateData();
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ConnectWithUs;
