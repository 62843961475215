import React from "react";
import {
  FormHelperText,
  Grid,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      fontSize: "0.875rem",
    },
  },
  input: {
    color: "#A6A6A6",
  },
  textareaStyle: {
    borderColor: "#A6A6A6",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #1181b2",
    },
    "&:active": {
      outline: "1px solid #1181b2",
    },
    "&::placeholder": {
      fontSize: "0.875rem",
      color: "#A6A6A6",
    },
  },
  textareaErrorStyle: {
    borderColor: "#d32f2f",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      borderColor: "#d32f2f",
    },
  },
});

const TextAreaComponent = ({
  disabled = false,
  errorText = "",
  color = "primary",
  fullWidth = true,
  value = "",
  placeholder = "",
  sx = {},
  onChange = () => {},
  onBlur = () => {},
  onClick = () => {},
  name = "",
  minrows = 3,
  maxrows = 6,
  textLabel = "",
  required = false,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid mb="3px" px="6px">
        <Typography
          className="ff-Ro fs-14 fw-600"
          sx={{ color: disabled ? "#ccc" : "#1f3763" }}
        >
          {textLabel}
          {required && <span className="text-danger ms-1">*</span>}
        </Typography>
      </Grid>
      <TextareaAutosize
        className={`rounded-2 px-2 ${
          Boolean(errorText)
            ? classes.textareaErrorStyle
            : classes.textareaStyle
        }`}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        color={color}
        style={{ width: fullWidth ? "100%" : "auto" }}
        value={value}
        sx={{
          ...sx,
          "&::placeholder": {
            fontSize: "0.875rem",
          },
        }}
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClick}
        classes={{ root: classes.customTextField }}
        maxRows={maxrows}
        minRows={minrows}
      />
      {errorText && (
        <FormHelperText error className="fw-700">
          {errorText}
        </FormHelperText>
      )}
    </>
  );
};

export default TextAreaComponent;
