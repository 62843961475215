import { Box,  Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AboutCareerCard from "../../molecules/cards/AboutCareerCard";
import { useNavigate, useParams } from "react-router-dom";
import LEARNING_CONTENT_DATA from "../../../constants/en.json";
import ProgramCard from "../../molecules/programCard/ProgramCard";
import CORPORATE_PROGRAMS_CONTENT from "../../../constants/ProgramsTabsData/elpTabsData";
const { CORPORATE_PROGRAMS } = LEARNING_CONTENT_DATA;

let S3_URL = process.env.REACT_APP_DYASHIN_S3_URL;
let EDIFY_S3_URL = process.env.REACT_APP_DSEDIFY_S3_URL;

let ELP = S3_URL + `/assets/services/Group 22035.svg`;
let VLP = S3_URL + `/assets/services/Group%2022008.svg`;
let ILP = S3_URL + `/assets/services/Group 22043.svg`;

function CorporatePrograms() {
  const { section } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        const yOffset = -60;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [section]);
  const handleButtonClick = (buttonLabel) => {
    if (buttonLabel === "Contact Us") {
      navigate("/contact-us");
    }
  };

  return (
    <div>
      <Box className="py-5 darkBlueBackground">
        <Grid container spacing={3}>
          <Grid item xs={0} md={1.5}></Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box>
              <Typography
                className="fw-700 px-3 corporate-page-title"
                lineHeight={1}
              >
                {CORPORATE_PROGRAMS._title_}
              </Typography>
              <Typography className="fs-16 fw-400 mt-4 campus-prog-text px-3">
                {CORPORATE_PROGRAMS._subTitle_}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            className="d-grid gap-4 "
            sx={{
              display: { xs: "none !important", sm: "block !important" },
            }}
          >
            <Grid
              item
              xs={12}
              className="d-flex justify-content-center align-items-center gap-5 p-2"
            >
              <AboutCareerCard
                text="Executive Learning Program"
                image={ELP}
              ></AboutCareerCard>
              <AboutCareerCard
                image={VLP}
                text="Virtual Learning Program"
              ></AboutCareerCard>
            </Grid>
            <Grid
              item
              xs={12}
              className="d-flex justify-content-center align-items-center p-2"
            >
              <AboutCareerCard
                image={ILP}
                text="Immersive Learning Program"
              ></AboutCareerCard>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {CORPORATE_PROGRAMS_CONTENT.map(
        ({ id, title, description, image, tabsData, buttonLabel }, index) => (
          <ProgramCard
            key={id}
            title={title}
            description={description}
            image={`${EDIFY_S3_URL}/${image}`}
            tabsData={tabsData}
            buttonLabel={buttonLabel}
            index={index}
            isLast={index === CORPORATE_PROGRAMS_CONTENT.length - 1}
            onBtnClick={() => handleButtonClick(buttonLabel)}
          />
        ),
      )}
    </div>
  );
}

export default CorporatePrograms;
