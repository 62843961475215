import LEARNING_CONTENT_DATA from "../en.json";

const {
  Elip_What_You_Will_Learn,
  Elip_How_It_Works,
  Eligibility_Criteria,
  How_It_Works,
  What_You_will_learn,
  Elip_Eligibility_Criteria_Description,
} = LEARNING_CONTENT_DATA;

const elipTabsData = [
  {
    iconContent: Eligibility_Criteria,
    description: Elip_Eligibility_Criteria_Description,
  },
  {
    iconContent: How_It_Works,
    details: Elip_How_It_Works,
  },
  {
    iconContent: What_You_will_learn,
    details: Elip_What_You_Will_Learn,
  },
];

export default elipTabsData;
