import { Box, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import ButtonComponent from "../../atoms/ButtonComponent/ButtonComponent";
import ProgramsTabComponent from "../ProgramsTabComponent/ProgramsTabComponent";

const ProgramCard = ({
  title,
  description,
  image,
  tabsData,
  buttonLabel,
  reverse,
  index,
  isLast,
  onBtnClick,
}) => {
  const isOdd = index % 2 === 0;

  return (
    <Box
      className="py-3 bgcolor"
      sx={
        isLast
          ? {
              paddingBottom: {
                xs: "40% !important",
                sm: "20% !important",
                md: "8% !important",
                lg: "5% !important",
              },
            }
          : {}
      }
    >
      <Grid
        container
        rowSpacing={2}
        className="mt-4 d-flex justify-content-center align-items-center gap-3"
      >
        {isOdd || reverse ? (
          <>
            <Grid
              item
              xs={12}
              sm={reverse ? 5 : 6}
              md={reverse ? 4 : 5}
              className="d-grid gap-5"
            >
              <Box id="immersive-learning">
                <Typography
                  className="fw-900 darkBlueTextHeading px-3 program-title"
                  gutterBottom
                  lineHeight={1.2}
                >
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                </Typography>
                <Typography
                  className="fs-16 darkBlueText px-3 prog-desc"
                  lineHeight={2.1}
                >
                  {description}
                </Typography>
              </Box>
              <Box className={`px-3 d-flex`}>
                <ButtonComponent
                  label={buttonLabel}
                  muiProps="enrollbtn"
                  borderRadius="20px"
                  onBtnClick={onBtnClick}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={reverse ? 6 : 5}
              md={reverse ? 5 : 4}
              className="d-flex justify-content-center"
            >
              <CardMedia
                component="img"
                className="prog-images"
                style={{
                  width: "90%",
                  objectFit: "contain",
                  borderRadius: "10px",
                }}
                image={image}
                alt="program image"
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              xs={12}
              sm={reverse ? 6 : 5}
              md={reverse ? 5 : 4}
              className="d-flex justify-content-center"
            >
              <CardMedia
                component="img"
                style={{
                  width: "90%",
                  objectFit: "contain",
                  borderRadius: "10px",
                }}
                image={image}
                alt="program image"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={reverse ? 5 : 6}
              md={reverse ? 4 : 5}
              className="d-grid gap-5"
            >
              <Box className="mt-3" id="immersive-learning">
                <Typography
                  className="program-title fw-900 darkBlueTextHeading px-3"
                  gutterBottom
                  lineHeight={1.2}
                >
                  {title}
                </Typography>
                <Typography
                  className="fs-16 darkBlueText px-3 prog-desc"
                  lineHeight="2.1"
                >
                  {description}
                </Typography>
              </Box>
              <Box className={`mt-2 d-flex justify-content-end px-3`}>
                <ButtonComponent
                  label={buttonLabel}
                  muiProps="enrollbtn"
                  borderRadius="20px"
                  onBtnClick={onBtnClick}
                />
              </Box>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <ProgramsTabComponent tabsData={tabsData} />
        </Grid>
      </Grid>
      {!isLast && <hr className="mt-5  hr" />}
    </Box>
  );
};

export default ProgramCard;
