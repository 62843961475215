import { Grid, Typography } from "@mui/material";
import React from "react";
import ContentSection from "../../../../molecules/ContentSection";
import { CARD_DATA } from "../../../../../constants/engineeringServices";

function EngineeringServices() {
  return (
    <Grid container px={0} justifyContent="center">
      <Grid item xs={10} md={10} lg={8}>
        <ContentSection title={"Engineering Services"}>
          <Grid container spacing={2}>
            {CARD_DATA.map((val, idx) => (
              <Grid
                item
                xs={12}
                md={5.5}
                key={idx}
                margin={1}
                px={2}
                className="technology-engg-services tech-services"
              >
                <Grid item container xs={12} padding={2}>
                  <Grid item xs={12}>
                    <Typography className="fw-700 fs-20" variant="h6">
                      {val.heading}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className="h-50" textAlign="start">
                    <Typography
                      className={`fw-400 fs-16 txt-description  ${
                        idx === 1 && "pb-5"
                      }`}
                    >
                      {val.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} paddingY={1.5} textAlign="center">
                    <img
                      className="service-img"
                      src={val.softwareIcons}
                      alt={val.heading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </ContentSection>
      </Grid>
    </Grid>
  );
}

export default EngineeringServices;
