import { Box, Grid, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
const S3_URL = process.env.REACT_APP_DYASHIN_S3_URL;
const icon = S3_URL + "/v3/assests/SubmitForm/submitIcon.png";

function SubmitForm({
  message = "Your application is successfully submitted",
  onSubModelClose = () => {},
}) {
  const useResponsiveWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (width >= 768) {
      return "9.5vw";
    } else {
      return "12vw";
    }
  };

  let width = useResponsiveWidth();

  const useResponsiveHeight = () => {
    const [height, setheight] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setheight(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (height >= 768) {
      return "18.52vh";
    } else {
      return "10vh";
    }
  };

  const Height = useResponsiveHeight();
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className="d-flex justify-content-end align-items-start"
      >
        <Button
          onClick={onSubModelClose}
          className="btn-hover-background"
          disableRipple
        >
          <CloseIcon className="closeicon-color" />
        </Button>
      </Grid>
      <Grid item xs={12} className="d-flex justify-content-center pt-2">
        <Box height={Height} width={width} className="">
          <img loading="lazy"src={icon} alt="submiticon" className="h-100 w-100" />
        </Box>
      </Grid>
      <Grid item xs={12} className="d-flex  justify-content-center">
        <Typography
          className="fw-700 fs-md-26 fs-xs-20 w-sm-50 w-md-25 mb-4 "
          textAlign={"center"}
        >
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SubmitForm;
