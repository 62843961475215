import { Avatar, Chip } from "@mui/material";
import EastIcon from "@mui/icons-material/East";

function TechnologyChipComponent({
  label = "",
  avatar = <Avatar></Avatar>,
  showAvatar = false,
  avatarPosition = "start",
  variant = "contained",
  size = "small",
  chipMuiProps = "",
  borderRadius = "20px",
  textColor = "",
  bgColor = "blue",
  fullWidth = true,
  disabled = false,
  children,
  iconPosition = "start",
  icon = <EastIcon />,
  showIcon = false,
  selected = false,
  sx = {},
  index = 0,
  onBtnClick = () => {},
  handleDelete = () => {},
  onMouseLeave = () => {},
  onMouseEnter = () => {},
}) {
  let IconProp = showIcon
    ? iconPosition === "start"
      ? {
          icon: icon,
        }
      : iconPosition === "center"
        ? { label: icon }
        : { deleteIcon: icon, onDelete: handleDelete }
    : {};

  let AvatarProp = showAvatar
    ? avatarPosition === "start"
      ? {
          avatar: avatar,
        }
      : {
          deleteIcon: avatar,
          onDelete: handleDelete,
        }
    : {};

  return (
    <Chip
      key={index}
      label={label}
      {...IconProp}
      {...AvatarProp}
      variant={variant}
      onClick={() => {
        onBtnClick(index);
      }}
      onMouseEnter={onMouseEnter}
      size={size}
      className={`p-3 fw-700 fs-16 text-uppercase ${chipMuiProps}`}
      sx={{
        height: 40,
        textTransform: "capitalize",
        letterSpacing: 2.4,
        bgcolor: selected ? "#24ABE3" : "#8DABE142",
        "&:hover": {
          bgcolor: selected ? "#24ABE3" : "#E0E0E0",
          color: selected ? "white" : "rgba(36, 171, 227, 1)",
        },
        borderRadius: borderRadius,
        color: selected ? "white" : "rgba(36, 171, 227, 1)",
        ...sx,
      }}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {children}
    </Chip>
  );
}

export default TechnologyChipComponent;
