import { Box, Chip, Typography } from "@mui/material";
import ButtonComponent from "../../atoms/ButtonComponent/ButtonComponent";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import "../../../styles/carousel.scss";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  ABOUTUS,
  CAMPUSPROGRAM,
  CAMPUSPROJECT,
  CORPORATE,
  EXPERIENTIAL,
  INTERSHIPPROGRAM,
  OFFCAMPUS,
  PROFESSIONALPROGRAM,
} from "../../../constants/carouselNew";
import { COLORS } from "../../../constants/colorConstants";
const { BLUE_1, PRIMARY } = COLORS;
const { _About_Us } = ABOUTUS;
const { _Campus_Program } = CAMPUSPROGRAM;
const { _off_Campus } = OFFCAMPUS;
const { _Corporate_Program } = CORPORATE;
const { _Intership_Program } = INTERSHIPPROGRAM;
const { _Campus_Project } = CAMPUSPROJECT;
const { _Professional_Program } = PROFESSIONALPROGRAM;
const { _Experiential_Program } = EXPERIENTIAL;
let EDIFY_S3_URL = process.env.REACT_APP_DSEDIFY_S3_URL;
let h1 = EDIFY_S3_URL+"/assetsWebp/homepage/about.webp";
let h2 = EDIFY_S3_URL+"/assetsWebp/homepage/campusprog.webp";
let h3 = EDIFY_S3_URL+"/assetsWebp/homepage/offcampus_mirror1.webp";
let h4 = EDIFY_S3_URL+"/assetsWebp/homepage/corporateprog3.webp";
let h5 = EDIFY_S3_URL+"/assetsWebp/homepage/cip.webp";
let h6 = EDIFY_S3_URL+"/assetsWebp/homepage/cpp2.webp";
let h7 = EDIFY_S3_URL+"/assetsWebp/homepage/pdp.webp";
let h8 = EDIFY_S3_URL+"/assetsWebp/homepage/elip.webp";
const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
};


function CarouselNew() {
  let navigate = useNavigate();
  return (
    <>
      <Box className="carousel-main">
        <Slider {...settings} className="slick ">
          <Carousel.Item>
            <Box className="CarouselItem programs-image-container position-relative">
              <img loading="lazy" src={h1} className="img" alt="Carousel" />
            </Box>

            <Carousel.Caption className="d-flex flex-column h-100 align-items-start justify-content-center carousel-Caption ">
              <Box className="d-flex flex-column align-items-start about-content">
                <Typography
                  lineHeight={1.2}
                  className=" fw-700 mt-1 carousel-title "
                >
                  About Us
                </Typography>

                <Typography className="fs-18 mt-2 aboutus-contents">
                  {_About_Us}
                </Typography>
              </Box>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <Box className="CarouselItem programs-image-container">
              <img loading="lazy" src={h2} className="img" alt="Carousel" />
            </Box>
            <Carousel.Caption className="d-flex flex-column h-100 align-items-start justify-content-center bottom-0">
              <Box className="d-flex flex-column h-100 align-items-start justify-content-center bottom-0 campus-main">
                <Typography
                  lineHeight={1.2}
                  className=" fw-700 mt-3 carousel-title"
                >
                  Campus Programs
                </Typography>
                <Typography className="fs-18 mt-1 campus-contents">
                  {_Campus_Program}
                </Typography>

                <ButtonComponent
                  onBtnClick={() => {
                    navigate("/campus-programs");
                  }}
                  label="Know more >"
                  className="carouselBtnColor mt-1"
                  sx={{
                    marginBottom: { xs: "25%", sm: "2%", md: "1%" },

                    backgroundColor:  PRIMARY,
                    borderRadius: "20px",
                    color: "white",
                    marginRight: "auto",
                    marginTop: "2%",
                    "&:hover": {
                      backgroundColor: BLUE_1,
                    },
                  }}
                />
              </Box>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <Box className="CarouselItem programs-image-container">
              <img  src={h3} className="img" alt="Carousel" />
            </Box>
            <Carousel.Caption className="d-flex flex-column h-100 align-items-start justify-content-center bottom-0">
              <Box className="d-flex flex-column h-100 align-items-start justify-content-center bottom-0 offcampus-main">
                <Typography lineHeight={1.2} className=" fw-700 carousel-title">
                  Off-Campus Programs
                </Typography>

                <Typography className="fs-18 mt-1 campus-contents">
                  {_off_Campus}
                </Typography>

                <ButtonComponent
                  onBtnClick={() => navigate("/off-campus-programs")}
                  label="Know more >"
                  className="carouselBtnColor mt-1"
                  sx={{
                    marginBottom: { xs: "35%", sm: "10%", md: "1%" },
                    backgroundColor:  PRIMARY,
                    borderRadius: "20px",
                    color: "white",
                    marginRight: "auto",
                    marginTop: { xs: "2%", sm: "4%", md: "2%" },
                    "&:hover": {
                      backgroundColor: BLUE_1,
                    },
                  }}
                />
              </Box>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <Box className="CarouselItem programs-image-container">
              <img loading="lazy" src={h4} className="img" alt="Carousel" />
            </Box>
            <Carousel.Caption className="d-flex flex-column h-100 align-items-start justify-content-center bottom-0">
              <Box className="d-flex flex-column h-100 align-items-start justify-content-center bottom-0 corporate-main">
                <Typography
                  lineHeight={1.2}
                  className=" fw-700 mt-1 carousel-title"
                >
                  Corporate Programs
                </Typography>

                <Typography className="fs-18 mt-1 campus-contents">
                  {_Corporate_Program}
                </Typography>
                <ButtonComponent
                  onBtnClick={() => {
                    navigate("/corporate-programs");
                  }}
                  label="Know more >"
                  className="carouselBtnColor mt-1"
                  sx={{
                    backgroundColor:  PRIMARY,
                    borderRadius: "20px",
                    color: "white",
                    marginRight: "auto",
                    marginTop: "2%",
                    marginBottom: { xs: "4%", sm: "4%" },
                    "&:hover": {
                      backgroundColor: BLUE_1,
                    },
                  }}
                />
              </Box>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <Box className="CarouselItem programs-image-container">
              <img loading="lazy" src={h5} className="img" alt="Carousel" />
            </Box>

            <Carousel.Caption className="d-flex flex-column h-100 align-items-start justify-content-center bottom-0">
              <Box className="d-flex flex-column h-100 align-items-start justify-content-center bottom-0 cip-main">
                <Chip label="Best In Class" color="warning" />
                <Typography lineHeight={1.2} className=" fw-700 mt-1 cip-title">
                  Campus Internship <br />
                  Programs
                </Typography>

                <Typography className="campus-contents">
                  {_Intership_Program}
                </Typography>
                <Box className="d-flex gap-3 ">
                  <ButtonComponent
                    onBtnClick={() => {
                      navigate("/campus-programs/cip");
                    }}
                    label="Read more >"
                    className="carouselBtnColor mt-1"
                    sx={{
                      backgroundColor:  PRIMARY,
                      borderRadius: "20px",
                      color: "white",
                      marginRight: "auto",
                      marginTop: "2%",
                      "&:hover": {
                        backgroundColor: BLUE_1,
                      },
                    }}
                  />
                  <ButtonComponent
                    onBtnClick={() => {
                      navigate("/enquiry");
                    }}
                    label="Enroll"
                    className="carouselBtnColor mt-1"
                    sx={{
                      backgroundColor:  PRIMARY,
                      borderRadius: "20px",
                      color: "white",
                      marginRight: "auto",
                      marginTop: "2%",
                      "&:hover": {
                        backgroundColor: BLUE_1,
                      },
                    }}
                  />
                </Box>
              </Box>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <Box className="CarouselItem programs-image-container">
              <img loading="lazy" src={h6} className="img" alt="Carousel" />
            </Box>

            <Carousel.Caption className="d-flex flex-column h-100 align-items-start justify-content-center bottom-0">
              <Box className="d-flex flex-column h-100 align-items-start justify-content-center bottom-0 cpp-main">
                <Chip label="Academic Projects" color="warning" />
                <Typography lineHeight={1.2} className=" fw-700  cip-title">
                  Campus Projects Program
                </Typography>

                <Typography className=" mt-1  cpp-contents">
                  {_Campus_Project}
                </Typography>
                <Box className="d-flex gap-3 mt-1">
                  <ButtonComponent
                    onBtnClick={() => {
                      navigate("/campus-programs/cpp");
                    }}
                    label="Read more >"
                    className="carouselBtnColor mt-1"
                    sx={{
                      backgroundColor:  PRIMARY,
                      borderRadius: "20px",
                      color: "white",
                      marginRight: "auto",
                      marginTop: "2%",
                      "&:hover": {
                        backgroundColor: BLUE_1,
                      },
                    }}
                  />
                  <ButtonComponent
                    onBtnClick={() => {
                      navigate("/enquiry");
                    }}
                    label="Enroll"
                    className="carouselBtnColor mt-1"
                    sx={{
                      backgroundColor:  PRIMARY,
                      borderRadius: "20px",
                      color: "white",
                      marginRight: "auto",
                      marginTop: "2%",
                      "&:hover": {
                        backgroundColor: BLUE_1,
                      },
                    }}
                  />
                </Box>
              </Box>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <Box className="CarouselItem programs-image-container">
              <img loading="lazy" src={h7} className="img" alt="Carousel" />
            </Box>

            <Carousel.Caption className="d-flex flex-column h-100 align-items-start justify-content-center bottom-0">
              <Box className="d-flex flex-column h-100 align-items-start justify-content-center bottom-0 pdp-main">
                <Chip label="Career Oriented" color="warning" />
                <Typography lineHeight={1.2} className=" fw-700  pdp-title">
                  Professional Development
                  <br />
                  Program
                </Typography>

                <Typography className=" mt-1 cpp-contents ">
                  {_Professional_Program}
                </Typography>
                <Box className="d-flex gap-3 mt-0.5">
                  <ButtonComponent
                    onBtnClick={() => {
                      navigate("/off-campus-programs/pdp");
                    }}
                    label="Read more >"
                    className="carouselBtnColor mt-1"
                    sx={{
                      backgroundColor:  PRIMARY,
                      borderRadius: "20px",
                      color: "white",
                      marginRight: "auto",
                      marginTop: "2%",
                      "&:hover": {
                        backgroundColor: BLUE_1,
                      },
                    }}
                  />
                  <ButtonComponent
                    onBtnClick={() => {
                      navigate("/enquiry");
                    }}
                    label="Enroll"
                    className="carouselBtnColor mt-1"
                    sx={{
                      backgroundColor:  PRIMARY,
                      borderRadius: "20px",
                      color: "white",
                      marginRight: "auto",
                      marginTop: "2%",
                      "&:hover": {
                        backgroundColor: BLUE_1,
                      },
                    }}
                  />
                </Box>
              </Box>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Box className="CarouselItem programs-image-container">
              <img loading="lazy" src={h8} className="img" alt="Carousel" />
            </Box>

            <Carousel.Caption className="d-flex flex-column h-100 align-items-start justify-content-center bottom-0">
              <Box className="d-flex flex-column h-100 align-items-start justify-content-center bottom-0 elip-main">
                <Chip label="ELIP" color="warning" />
                <Typography lineHeight={1.2} className=" fw-700 mt-1 cpp-title">
                  Experiential Learning <br /> Internship Program
                </Typography>

                <Typography className=" mt-1 cpp-contents">
                  {_Experiential_Program}
                </Typography>
                <Box className="d-flex gap-3 mt-3">
                  <ButtonComponent
                    onBtnClick={() => {
                      navigate("/off-campus-programs/elip");
                    }}
                    label="Read more >"
                    className="carouselBtnColor mt-1"
                    sx={{
                      backgroundColor: PRIMARY,
                      borderRadius: "20px",
                      color: "white",
                      marginRight: "auto",
                      marginTop: "2%",
                      "&:hover": {
                        backgroundColor: BLUE_1,
                      },
                    }}
                  />
                  <ButtonComponent
                    onBtnClick={() => {
                      navigate("/enquiry");
                    }}
                    label="Enroll"
                    className="carouselBtnColor mt-1"
                    sx={{
                      backgroundColor:  PRIMARY,
                      borderRadius: "20px",
                      color: "white",
                      marginRight: "auto",
                      marginTop: "2%",
                      "&:hover": {
                        backgroundColor: BLUE_1,
                      },
                    }}
                  />
                </Box>
              </Box>
            </Carousel.Caption>
          </Carousel.Item>
        </Slider>
      </Box>
    </>
  );
}

export default CarouselNew;
