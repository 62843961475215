import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Grid,
  Tooltip,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ChipComponent from "../../atoms/ChipComponent";
import ButtonComponent from "../../atoms/ButtonComponent/ButtonComponent";
import { COLORS } from "../../../constants/colorConstants";
import serviceUtil from "../../../services/utils/index";
const { BLUE_1, ORANGE_1, WHITE_3, PRIMARY } = COLORS;

const BatchCardComponent = ({
  image = {},
  title = "",
  description = "",
  batchStartDate = "",
  batchEndDate = "",
  batchStartTime = "",
  batchEndTime = "",
  batchMode = "",
  btnlabel = "Enquire Now",
  altText = "Batch logo",
  onBtnClick = () => {},
}) => {
  const { displayDateFormat, formatTo12Hour } = serviceUtil;
  return (
    <Card className="batch-card">
      <Box height={"28vh"}>
        <CardMedia
          component="img"
          image={image}
          alt={altText}
          className="px-2"
        />
      </Box>

      <CardContent>
        <Grid container>
          <Grid
            item
            className="d-flex justify-content-start align-items-center gap-3 upcoming-batch"
            width={"100%"}
          >
            <ChipComponent
              label={batchMode}
              color={ORANGE_1}
              borderColor={ORANGE_1}
            />
          </Grid>
          <Grid item xs={12}>
            <Tooltip title={title} placement="bottom">
              <Typography className="fw-700 mt-2 text-center our-batches">
                {title}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Typography className="fw-400 mt-1 text-center description">
              {description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className="d-flex justify-content-between align-items-center gap-2 mt-3">
              <Box className="d-flex align-items-center gap-1">
                <EventIcon className="event-icon" />
                <Typography className="fs-12 fw-600">
                  {displayDateFormat(batchStartDate)} -{" "}
                  {displayDateFormat(batchEndDate)}
                </Typography>
              </Box>
              <Box className="d-flex gap-2 align-items-center">
                <ScheduleIcon className="schedule-icon" />
                <Typography className="fs-12 fw-600">
                  {formatTo12Hour(batchStartTime)} -{" "}
                  {formatTo12Hour(batchEndTime)}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            className="d-flex justify-content-end align-items-center mt-4"
          >
            <ButtonComponent
              onBtnClick={onBtnClick}
              label={btnlabel}
              fullWidth
              sx={{
                backgroundColor: PRIMARY,
                color: WHITE_3,
                marginRight: "auto",
                marginTop: "2%",
                "&:hover": {
                  backgroundColor: BLUE_1,
                },
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BatchCardComponent;
