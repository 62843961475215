const validateMessage = {
  field_required: "This field is required",
  email: "Invalid Email",
  mobile: "Invalid Mobile Number",
  alternativeNumber: "Alternate Number should not be same as Contact Number",
  maxLength: "Max length should be 30 characters",
  password:
    "Minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character @ # $",
  userId: "Invalid Email / Mobile Number",
  alphabets: "Only alphabets are allowed",
  selectImage: "Select an image file",
  selectOption: "Select an option",
  selectAtLeastOneOption: "Please select at least one option",
  resumeSize: "Resume size must be 5 mb or below.",
  pdfFiles: "Only PDF files are allowed.",
};
export default validateMessage;
