import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function TabsCard({
  icon = "",
  iconContent = "Product Design",
  selected = false,
  index = 0,
  sxcard = {},
  sxtext = {},
  onCardClick = () => {},
}) {
  return (
    <Grid container className="h-100 cursor-pointer">
      <Grid
        container
        item
        sm={12}
        onClick={() => {
          onCardClick(index);
        }}
        bgcolor={selected ? "rgba(36, 171, 227, 1)" : "white"}
        sx={{ ...sxcard }}
        height={50}
        width={160}
      >
        <Grid item xs={12} className="d-flex justify-content-center">
          {icon && <Box>{icon}</Box>}
        </Grid>
        <Grid item xs={12} className="d-flex justify-content-center">
          <Typography
            className="fw-700 text-center py-2 "
            color={selected ? "white" : " black"}
            sx={{
              ...sxtext,
              fontSize: { xs: "60%", sm: "60%", md: "80%", lg: "80%" },
            }}
          >
            {iconContent}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TabsCard;
