import LEARNING_CONTENT_DATA from "../en.json";

const {
  Tcp_What_You_Will_Learn,
  Tcp_How_It_Works,
  Eligibility_Criteria,
  How_It_Works,
  What_You_will_learn,
  Tcp_Eligibility_Criteria_Description,
} = LEARNING_CONTENT_DATA;

const tcpTabsData = [
  {
    iconContent: Eligibility_Criteria,
    description: Tcp_Eligibility_Criteria_Description,
  },
  {
    iconContent: How_It_Works,
    details: Tcp_How_It_Works,
  },
  {
    iconContent: What_You_will_learn,
    details: Tcp_What_You_Will_Learn,
  },
];

export default tcpTabsData;
