import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import NavbarRes from "./NavbarRes";
import navTheme from "./navTheme";
function CustomNavbar() {
  return (
    <>
      <ThemeProvider theme={navTheme}>
        <CssBaseline />
        <NavbarRes />
      </ThemeProvider>
    </>
  );
}

export default CustomNavbar;
